<template>
  <section id="categorias">
    <b-overlay
      :show="hiddeSearchInput"
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row class="mb-5">
        <b-col
          md="8"
          class="pb-3 pb-md-0"
        >
          <template v-if="isContent">
            <h1>{{ $t("contents.categoryContents") }}</h1>
            <h5 class="text-primary">
              {{ $t("contents.listCategoryContents") }}
            </h5>
          </template>

          <template v-else>
            <div v-if="seeker">
              <h1>{{ $t("contents.searchCategories") }}</h1>
            </div>
            <div v-else>
              <h1>{{ $t("contents.categories") }}</h1>
              <h5 class="text-primary">
                {{ $t("contents.listCategory") }}
              </h5>
            </div>
          </template>
        </b-col>
      </b-row>
      <!-- MENSAJE DE ALERTA PARA INDICAR LIMITE DE CATEGORÍAS -->
      <b-row>
        <b-col>
          <categories-alerts
            :show-alert="showAlert"
            :show-alert-content="showAlertContent"
          />
        </b-col>
      </b-row>
      <b-row>
        <!-- RUTA DE NAVEGACIÓN POR CATEGORÍAS -->
        <b-col md="11">
          <b-breadcrumb v-if="!isSponsor">
            <b-breadcrumb-item
              v-for="(brc, index) in navItems"
              :key="brc.text + '_' + brc.id"
              :text="brc.text"
              :active="index === navItems.length - 1 ? true : false"
              @click.stop.prevent="index !== navItems.length - 1 ? getElement(brc.id, link ? $route.params.link : null) : ''"
            />
          </b-breadcrumb>
          <b-breadcrumb v-else>
            <b-breadcrumb-item
              v-for="(brc, index) in navItems"
              :key="brc.text + '_' + brc.id"
              :text="brc.text"
              :active="index != 0 ? true : false"
              @click.stop.prevent="index == 0 ? getElement(null, null) : ''"
            />
          </b-breadcrumb>
        </b-col>
        <!-- BUTTON EDITAR CATEGORIA -->
        <b-col
          md="1"
          class="d-flex justify-content-end align-items-center"
        >
          <div
            v-if="navItems.length > 1"
            class="mr-2"
          >
            <b-button
              v-b-tooltip.hover.top.v-primary="$t('contents.editCat')"
              variant="outline-primary"
              class="d-flex align-items-center"

              @click.stop.prevent="routeToEditView(navItems[navItems.length - 1].id)"
            >
              <feather-icon
                icon="EditIcon"
                size="18"
                class="mr-50 cursor-pointer"
              />
              {{ $t('Edit') }}
            </b-button>
          </div>
          <!-- BUTTON VOLVER -->
          <div v-if="navItems.length > 1">
            <div v-if="!isSponsor">
              <b-button
                variant="danger"
                @click.stop.prevent="getElement(navItems[navItems.length - 2].id, link ? $route.params.link : null)"
              >
                <span class="text-nowrap">
                  {{ $t("dataGeneric.goBack") }}
                </span>
              </b-button>
            </div>
            <div v-else>
              <b-button
                variant="danger"
                @click.stop.prevent="getElement(null, null, true)"
              >
                <span class="text-nowrap">
                  {{ $t("dataGeneric.goBack") }}
                </span>
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- APARTADO ORDENACIÓN MANUAL -->
      <b-row class="mt-2">
        <b-col
          v-if="manual"
          class="d-flex align-items-center"
        >
          <b-button
            variant="outline-warning"
            disabled
          >
            {{ $t("contents.manualSortMode") }}
          </b-button>
          <b-button
            class="ml-2"
            variant="success"
            @click.stop.prevent="ordenar('MAN')"
          >
            {{ $t("contents.finalize") }}
          </b-button>
        </b-col>
        <b-col
          v-if="!isContent && !manual"
          class="align-items-center d-flex"
        >
          <b-form-input
            id="search"
            v-model="searchInput"
            type="text"
            name="search"
            :disabled="selectCat || hiddeSearchInput"
            :placeholder="searchInputPlaceholder"
            class="searchCategories ml-1 mr-1"
            @keyup="onKeyUp($event)"
            @focus="searching"
          />
          <b-button
            v-if="seeker"
            variant="info"
            class="mr-1"
            @click.stop.prevent="onKeyUp($event,true)"
          >
            <feather-icon
              icon="SearchIcon"
              size="18"
            />
          </b-button>
          <b-button
            v-if="seeker"
            variant="danger"
            @click.stop.prevent="cleanSearch"
          >
            {{ $t('dataGeneric.cancel') }}
          </b-button>
        </b-col>
        <!-- SELECCIÓN DE CONTENIDOS MÚLTIPLES, FILTROS Y OPCIONES -->
        <b-col v-if="isContent && !manual">
          <b-row>
            <b-col>
              <b-form-checkbox
                v-model="seleccionar"
                switch
                :disabled="totalContents == 0 ? true : false"
              >
                {{ $t('activeSelectionContens') }}
              </b-form-checkbox>
            </b-col>
            <b-col md="2">
              <b-form-select
                v-if="seleccionar"
                v-model="state"
                :options="optionsStatus"
              />
            </b-col>
            <b-col
              v-if="seleccionar"
              md="2"
            >
              <b-button
                v-if="choicesContents.length > 0 && state != null"
                variant="success"
                @click.stop.prevent="setStatus()"
              >
                {{ $t('dataGeneric.change') }}
              </b-button>
              <b-button
                v-else
                disabled
              >
                {{ $t('dataGeneric.change') }}
              </b-button>
            </b-col>
            <b-col
              v-if="seleccionar"
              md="2"
            >
              <b-dropdown
                right
                variant="primary"
                class="mr-1"
                :text="$t('resources.tableHeader.actions')"
              >
                <b-dropdown-item
                  :disabled="choicesContents.length == 0 ? true : false"
                  @click.stop.prevent="actions = 'ADD'"
                >
                  {{ $t('addToCategory') }}
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="choicesContents.length == 0 ? true : false
                  "
                  @click.stop.prevent="actions = 'MOV'"
                >
                  {{ $t('contents.moveContent') }}
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="choicesContents.length == 0 ? true : false
                  "
                  @click.stop.prevent="actions = 'REM'"
                >
                  {{ $t('removeFromCat') }}
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="choicesContents.length == 0 ? true : false"
                  variant="danger"
                  @click.stop.prevent="actions = 'DEL'"
                >
                  {{ $t('dataGeneric.delete') }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-checkbox
                v-if="seleccionar"
                v-model="todos"
                switch
              >
                {{
                  $t('slider.SelectElements', {
                    x: contentData.length
                  })
                }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
        <!-- TIPO DE ORDENACIÓN DE LA CATEGORÍA -->
        <b-col
          v-if="!seeker"
          class="d-flex justify-content-end"
          md="3"
          style="height: fit-content;"
        >
          <!-- MODOS DE ORDENACIÓN SI ES O NO CONTENIDO-->
          <div v-if="!isContent || enlace">
            <b-dropdown
              v-if="!isSponsor || code != null"
              right
              class="mr-1"
            >
              <template #button-content>
                {{ orderList[orderType] }}
              </template>
              <b-dropdown-item @click.stop.prevent="ordenar('MAN')">
                {{ $t("contents.sortManual") }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <!-- ORDENACIÓN DE CONTENIDOS -->
          <b-dropdown
            v-if="isContent && !enlace"
            right
            class="mr-1"
            :disabled="manual"
          >
            <template #button-content>
              {{ orderList[orderType] }}
            </template>
            <b-dropdown-item
              :disabled="isEmpty(contentData)"
              @click.stop.prevent="ordenar('ALF')"
            >
              {{ $t("alfa") }}
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="isEmpty(contentData)"
              @click.stop.prevent="ordenar('MAN')"
            >
              {{ $t("contents.sortManual") }}
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="isEmpty(contentData)"
              @click.stop.prevent="ordenar('EAS')"
            >
              {{ $t('emit') }}
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="isEmpty(contentData)"
              @click.stop.prevent="ordenar('EDE')"
            >
              {{ $t('emitReverse') }}
            </b-dropdown-item>
          </b-dropdown>

          <!-- BUTTON ADD PARA CATEOGORÍAS DE TIPO ENLACE -->
          <div v-if="enlace">
            <b-button
              variant="primary"
              @click.stop.prevent="showModal('enlace')"
            >
              <span class="text-nowrap">
                <feather-icon
                  icon="FolderPlusIcon"
                  class="mr-50"
                />
                {{ $t('linkCateg') }}</span>
            </b-button>
          </div>
          <div v-else>
            <!-- BUTTON_GROUP CREAR CONTENIDO Y AÑADIR CONTENIDO -->
            <b-button-group
              v-if="isContent"
              class="float-right"
            >
              <b-dropdown
                variant="primary"
                right
                :text="$t('creatCont')"
              >
                <b-dropdown-item @click.stop.prevent="createNewContent">
                  {{
                    $t("contents.createContent")
                  }}
                </b-dropdown-item>
                <b-dropdown-item @click.stop.prevent="showModal('addContent')">
                  {{
                    $t("contents.addContent")
                  }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!isPod"
                  :disabled="isEmpty(contentData)"
                  @click.stop.prevent="showModal('catgCont')"
                >
                  {{ $t('catgCont') }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userData.isSuperuser && isDevelopment"
                  @click.stop.prevent="showModal('superuser')"
                >
                  <b-badge variant="warning">
                    Duplicar contenidos - DEMOS
                  </b-badge>
                </b-dropdown-item>
              </b-dropdown>
            </b-button-group>
            <!-- BUTTON CREAR NUEVA CATEGORÍA-->
            <div v-else>
              <b-button
                v-if="!isSponsor || code != null"
                :disabled="overlay ? true : false"
                variant="primary"
                class="d-flex"
                @click.stop.prevent="createNewCategory"
              >
                <feather-icon
                  icon="FolderPlusIcon"
                  class="mr-50"
                />
                <span class="text-nowrap">{{ $t("contents.addCategory") }}</span>
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- NÚMERO DE ELEMENTOS -->
      <b-row v-if="!seeker">
        <b-col class="d-flex justify-content-end mt-2">
          <span>
            <strong>{{ isContent ? totalContents : totalCategories }}</strong> {{ $t("contents.elements") }}
          </span>
        </b-col>
      </b-row>
      <b-row
        v-if="!isContent && !seeker && !manual"
        class=" mb-1"
      >
        <b-col md="3">
          <b-form-checkbox
            v-model="selectCat"
            :disabled="totalCategories == 0"
            switch
          >
            {{ $t('activeSelectionCategory') }}
          </b-form-checkbox>
        </b-col>
        <b-col
          v-if="selectCat"
          md="2"
        >
          <b-form-select
            v-model="statusCat"
            :options="optionsStatus"
          />
        </b-col>
        <b-col
          v-if="selectCat"
          md="2"
        >
          <b-button
            v-if="selectCategory.length > 0 && statusCat != null"
            variant="success"
            @click.stop.prevent="setStatusCat()"
          >
            {{ $t('dataGeneric.change') }}
          </b-button>
          <b-button
            v-else
            disabled
          >
            {{ $t('dataGeneric.change') }}
          </b-button>
        </b-col>
        <b-col
          v-if="selectCat"
          md="2"
        >
          <b-dropdown
            right
            variant="primary"
            class="mr-1"
            :text="$t('resources.tableHeader.actions')"
          >
            <div v-if="!enlace">
              <b-dropdown-item
                :disabled="selectCategory.length == 0 ? true : false
                "
                @click.stop.prevent="showModal('moveToCat', { node: { type: '', id: null } })"
              >
                {{ $t('contents.moveToCat') }}
              </b-dropdown-item>

              <b-dropdown-item
                :disabled="selectCategory.length == 0 ? true : false"
                variant="danger"
                @click.stop.prevent="setDeleteCat()"
              >
                {{ $t('dataGeneric.delete') }}
              </b-dropdown-item>
            </div>
            <div v-else>
              <b-dropdown-item
                :disabled="selectCategory.length == 0 ? true : false"
                @click.stop.prevent="removeSelectLink()"
              >
                <feather-icon
                  icon="DeleteIcon"
                  size="18"
                />
                {{ $t('dropLink') }}
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row
        v-if="!isContent && !seeker && !manual"
        class="mb-2"
      >
        <b-col>
          <b-form-checkbox
            v-if="selectCat"
            v-model="allCat"
            switch
          >
            {{
              $t('slider.SelectElements', {
                x: enlace ? linkCategory.length :
                  childCategory.length
              })
            }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <div
        v-if="seeker"
        class="mt-2 ml-1"
      >
        <b-row class="align-items-center">
          <b-col md="3">
            <b-form-select
              v-model="type"
              :disabled="selectCat"
            >
              <b-form-select-option :value="null">
                {{ $t('purchases.type') }}
              </b-form-select-option>
              <b-form-select-option
                v-for="category in Object.keys(categoryTypes)"
                :key="category"
                :value="category"
              >
                {{
                  $t(categoryTypes[category])
                }}
              </b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col md="2">
            <b-form-select
              v-model="isFinal"
              :disabled="selectCat"
              :options="[
                { text: $t('finAndNot'), value: null },
                { text: $t('Final'), value: true },
                { text: $t('noFinal'), value: false },
              ]"
            />
          </b-col>
          <b-col md="2">
            <b-form-select
              v-model="stateCat"
              :disabled="selectCat"
              :options="[
                { value: null, text: $t('subscriptions.status') },
                { value: true, text: $t('catActv') },
                { value: false, text: $t('catdeactv') },
              ]"
            />
          </b-col>
          <b-col md="2">
            <b-form-select
              v-model="parentCat"
              :disabled="selectCat"
              :options="[
                { value: null, text: $t('filter') },
                { value: true, text: $t('rootCat') },
                { value: false, text: $t('subCat') },
              ]"
            />
          </b-col>
          <b-col>
            <b-pagination
              v-if="rows > perPage"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              size="lg"
              class="mt-1"
            />
          </b-col>
        </b-row>
        <!-- OPCIÓN MÚLTIPLE PARA EL BUSCADOR -->
        <b-row
          v-if="!isContent && !manual"
          class="mt-1 mb-1"
        >
          <b-col md="3">
            <b-form-checkbox
              v-model="selectCat"
              :disabled="totalCategories == 0"
              switch
            >
              {{ $t('activeSelectionCategory') }}
            </b-form-checkbox>
          </b-col>
          <b-col
            v-if="selectCat"
            md="2"
          >
            <b-form-select
              v-model="statusCat"
              :options="optionsStatus"
            />
          </b-col>
          <b-col
            v-if="selectCat"
            md="2"
          >
            <b-button
              v-if="selectCategory.length > 0 && statusCat != null"
              variant="success"
              @click.stop.prevent="setStatusCat()"
            >
              {{ $t('dataGeneric.change') }}
            </b-button>
            <b-button
              v-else
              disabled
            >
              {{ $t('dataGeneric.change') }}
            </b-button>
          </b-col>
          <b-col
            v-if="selectCat"
            md="2"
          >
            <b-dropdown
              right
              variant="primary"
              class="mr-1"
              :text="$t('resources.tableHeader.actions')"
            >
              <b-dropdown-item
                :disabled="selectCategory.length == 0 ? true : false
                "
                @click.stop.prevent="showModal('moveToCat', { node: { type: '', id: null } })"
              >
                {{ $t('contents.moveToCat') }}
              </b-dropdown-item>

              <b-dropdown-item
                :disabled="selectCategory.length == 0 ? true : false"
                variant="danger"
                @click.stop.prevent="setDeleteCat()"
              >
                {{ $t('dataGeneric.delete') }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
        <b-row
          v-if="!isContent && !manual"
          class="mb-2"
        >
          <b-col>
            <b-form-checkbox
              v-if="selectCat"
              v-model="allCat"
              switch
            >
              {{
                $t('slider.SelectElements', {
                  x: rows
                })
              }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="rows !== 0">
            {{ $t('resultFounds', { length: rows }) }}
          </b-col>
          <b-col v-else>
            {{ $t('resultNotFound') }}
          </b-col>
        </b-row>

        <search-categories
          ref="searchCat"
          class="mt-2"
          :is-final="isFinal"
          :state="stateCat"
          :parent="parentCat"
          :set-is-active="setIsActive"
          :go-to-init="goToInit"
          :update-parent="updateParentToInit"
          :child-category="childCategory"
          :sponsor="isSponsor"
          :select-cat="selectCat"
          :delete-category="deleteCategory"
          :show-modal="showModal"
          :all-cat="allCat"
          :search="search"
          :current-page="currentPage"
          :per-page="perPage"
          :type="type"
          @pagCount="pagCount"
          @returnCat="getSelection"
        />
      </div>
      <div v-else>
        <!-- CONTENIDOS -->
        <div v-if="isContent && !enlace">
          <b-tabs
            v-model="tabIndex"
            class="mt-2"
            pills
            lazy
          >
            <b-tab>
              <template #title>
                <feather-icon icon="GridIcon" />
                <span>{{ $t('category.card') }}</span>
              </template>
              <contents-list-card
                :is-pod="isPod"
                :manual="manual"
                :sponsor="isSponsor"
                :actions="actions"
                :choice="seleccionar"
                :todos="todos"
                :code="code"
                :loading="overlay"
                :contenidos="contentData"
                @reload="reloadData"
                @choices="contChoice"
                @actReturn="actReturn"
                @vacio="vacio"
              />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="ListIcon" />
                <span>{{ $t('List') }}</span>
              </template>
              <contents-table
                :is-pod="isPod"
                :sponsor="isSponsor"
                :code="code"
                :contenidos="contentData"
                :manual="manual"
                @reload="reloadData"
              />
            </b-tab>
          </b-tabs>
        </div>
        <!-- CATEGORÍAS -->
        <b-tabs
          v-else
          v-model="tabIndex"
          pills
          lazy
        >
          <!-- GRID -->
          <b-tab>
            <template #title>
              <feather-icon icon="GridIcon" />
              <span> {{ $t("Cards") }}</span>
            </template>
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="selectCategory"
              name="some-radios"
            >
              <b-overlay
                variant="transparent"
                :show="overlay"
                :opacity="1.00"
                :blur="'2px'"
                rounded="lg"
              >
                <template
                  v-if="isLoading"
                  #overlay
                >
                  <b-icon
                    icon="stopwatch"
                    variant="info"
                    scale="2"
                    shift-v="8"
                    shift-h="8"
                    class="position-absolute"
                    style="top: 0; right: 0"
                  />
                </template>
                <div v-if="enlace == false">
                  <draggable
                    v-if="manual"
                    :list="childCategory"
                    class="row rounded manualOrder"
                    group="categories"
                    :sort="orderEstructure"
                    @choose="chooseCategory"
                  >
                    <b-col
                      v-for="folder in childCategory"
                      :id="folder.node.id + ' ' + folder.node.order"
                      :key="folder.node.id"
                      class="mt-5 hang-on-hover"
                      md="6"
                      lg="4"
                      xl="3"
                      sm="12"
                      @dragend="updateOrder($event)"
                    >
                      <b-card
                        class="carCat"
                        no-body
                      >
                        <b-img
                          :src="buildImageUrl(folder)"
                          :class="manual ? 'card-img-top cursor-move' : 'card-img-top cursor-pointer'"
                          img-alt="Card image"
                          fluid-grow
                          @error="errorImg"
                        />
                        <b-card-header>
                          <div id="acortarPadre">
                            <b-card-title
                              id="acortar"
                              class="mb-25"
                            >
                              <span
                                v-if="folder.node.state == 'GRE'"
                                class="mr-50 bullet bullet-success bullet-sm"
                              />
                              <span
                                v-if="folder.node.state == 'YEW'"
                                class="mr-50 bullet bullet-warning bullet-sm"
                              />
                              <span
                                v-if="folder.node.state == 'RED'"
                                class="mr-50 bullet bullet-danger bullet-sm"
                              />
                              <span
                                v-if="folder.node.state == 'BCK'"
                                class="mr-50 bullet bullet-dark bullet-sm"
                              />
                              {{
                                folder.node.name
                              }}
                            </b-card-title>
                            <b-card-text class="mb-0">
                              {{ $t(categoryTypes[folder.node.type]) }}
                            </b-card-text>
                          </div>
                        </b-card-header>
                      </b-card>
                    </b-col>
                  </draggable>
                  <b-row v-else>
                    <b-col
                      v-for="folder in childCategory"
                      :id="folder.node.id + ' ' + folder.node.order"
                      :key="folder.node.id"
                      class="mt-5"
                      md="6"
                      lg="4"
                      xl="3"
                      sm="12"
                    >
                      <b-card
                        class="carCat"
                        no-body
                        @click.stop.prevent="!selectCat ? (specialCategory(folder.node.type) ? catEspecial(folder) : getElement(folder.node.id, link ? $route.params.link : folder.node.type)) : clickCheck(folder.node)"
                      >
                        <b-img
                          :src="buildImageUrl(folder)"
                          class="card-img-top cursor-pointer"
                          img-alt="Card image"
                          fluid-grow
                          @error="errorImg"
                        />
                        <b-card-header>
                          <div id="acortarPadre">
                            <b-card-title
                              id="acortar"
                              class="mb-25"
                            >
                              <span
                                v-if="folder.node.state == 'GRE'"
                                class="mr-50 bullet bullet-success bullet-sm"
                              />
                              <span
                                v-if="folder.node.state == 'YEW'"
                                class="mr-50 bullet bullet-warning bullet-sm"
                              />
                              <span
                                v-if="folder.node.state == 'RED'"
                                class="mr-50 bullet bullet-danger bullet-sm"
                              />
                              <span
                                v-if="folder.node.state == 'BCK'"
                                class="mr-50 bullet bullet-dark bullet-sm"
                              />

                              {{
                                folder.node.name
                              }}
                            </b-card-title>
                            <b-card-text class="mb-0">
                              {{ $t(categoryTypes[folder.node.type]) }}
                            </b-card-text>
                          </div>
                        </b-card-header>

                        <b-card-body>
                          <div
                            v-if="!selectCat"
                            class="actions"
                          >
                            <b-dropdown
                              :text="folder.node.state == 'GRE' ? $t('Activada') : folder.node.state == 'RED' ? $t('Desactivada') : $t('Interna')"
                              :variant="folder.node.state == 'GRE' ? 'outline-success' : folder.node.state == 'RED' ? 'outline-danger' : 'outline-warning'"
                              class="p-2"
                              size="sm"
                            >
                              <b-dropdown-item @click.stop.prevent="setIsActive(folder.node, 'RED')">
                                🔴 {{ $t('Desactivada') }}
                              </b-dropdown-item>
                              <b-dropdown-item @click.stop.prevent="setIsActive(folder.node, 'YEW')">
                                🟡 {{ $t('Interna') }}
                              </b-dropdown-item>
                              <b-dropdown-item @click.stop.prevent="setIsActive(folder.node, 'GRE')">
                                🟢 {{ $t('Activada') }}
                              </b-dropdown-item>
                            </b-dropdown>

                            <div class="action">
                              <feather-icon
                                v-show="sizeWindow < 1250 || sizeWindow > 1630"
                                class="cursor-pointer"
                                icon="Edit2Icon"
                                size="20"
                                @click.stop.prevent="routeToEditView(folder.node.id)"
                              />

                              <b-dropdown
                                variant="link"
                                toggle-class="text-decoration-none"
                                no-caret
                                right
                              >
                                <template #button-content>
                                  <feather-icon
                                    color="white"
                                    icon="MoreVerticalIcon"
                                    size="20"
                                  />
                                </template>
                                <b-dropdown-item
                                  v-show="sizeWindow >= 1250 && sizeWindow <= 1630"
                                  @click.stop.prevent="routeToEditView(folder.node.id)"
                                >
                                  <feather-icon
                                    class="cursor-pointer"
                                    icon="Edit2Icon"
                                    size="18"
                                  />
                                  {{ $t("Edit") }}
                                </b-dropdown-item>
                                <b-dropdown-item
                                  v-if="goToInit(folder)"
                                  @click.stop.prevent="updateParentToInit(folder)"
                                >
                                  <feather-icon
                                    icon="ArrowUpIcon"
                                    size="18"
                                  />
                                  {{ $t("contents.moveToInit") }}
                                </b-dropdown-item>
                                <b-dropdown-item
                                  v-if="!isSponsor"
                                  @click.stop.prevent="showModal('moveToCat', folder.node)"
                                >
                                  <feather-icon
                                    icon="CornerRightUpIcon"
                                    size="18"
                                  />
                                  {{ $t("contents.moveToCat") }}
                                </b-dropdown-item>

                                <b-dropdown-item @click.stop.prevent="deleteCategory(folder.node.id)">
                                  <feather-icon
                                    icon="DeleteIcon"
                                    size="18"
                                  />
                                  {{ $t("contents.dropCat") }}
                                </b-dropdown-item>
                                <b-dropdown-item v-if="folder.node.type == 'POD'">
                                  <div
                                    v-clipboard:copy="feedPodcast"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError"
                                    class="align-items-center"
                                    @click.stop.prevent="generateXml(folder.node.id)"
                                  >
                                    <feather-icon
                                      icon="MicIcon"
                                      size="18"
                                    />
                                    {{ $t('feedRssPodcast') }}
                                  </div>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </div>
                          <div
                            v-else
                            class="actionsBar right"
                          >
                            <div class="action">
                              <b-form-checkbox
                                disabled
                                :value="folder.node.id"
                              />
                            </div>
                          </div>
                        </b-card-body>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  <draggable
                    v-if="manual"
                    :list="linkCategory"
                    class="row manualOrder rounded"
                    group="categories"
                    :sort="orderEstructure"
                  >
                    <b-col
                      v-for="linked in linkCategory"
                      :id="linked.node.id + ' ' + linked.node.order"
                      :key="linked.node.linkCategory.id"
                      class="mt-5 cursor-move"
                      md="6"
                      lg="4"
                      xl="3"
                      sm="12"
                      @dragend="updateOrderLink($event)"
                    >
                      <b-card
                        class="carCat"
                        no-body
                      >
                        <b-img
                          :src="buildImageUrl({ node: linked.node.linkCategory })"
                          class="card-img-top"
                          img-alt="Card image"
                          fluid-grow
                          @error="errorImg"
                        />
                        <b-card-header>
                          <div id="acortarPadre">
                            <b-card-title
                              id="acortar"
                              class="mb-25"
                            >
                              <span
                                v-if="linked.node.linkCategory.state == 'GRE'"
                                class="mr-50 bullet bullet-success bullet-sm"
                              />
                              <span
                                v-if="linked.node.linkCategory.state == 'YEW'"
                                class="mr-50 bullet bullet-warning bullet-sm"
                              />
                              <span
                                v-if="linked.node.linkCategory.state == 'RED'"
                                class="mr-50 bullet bullet-danger bullet-sm"
                              />
                              <span
                                v-if="linked.node.linkCategory.state == 'BCK'"
                                class="mr-50 bullet bullet-dark bullet-sm"
                              />
                              {{
                                linked.node.linkCategory.name
                              }}
                            </b-card-title>
                            <b-card-text class="mb-0">
                              {{ $t('linkToCatg') }}
                            </b-card-text>
                          </div>
                        </b-card-header>
                      </b-card>
                    </b-col>
                  </draggable>
                  <b-row v-else>
                    <b-col
                      v-for="linked in linkCategory"
                      :id="linked.node.id + ' ' + linked.node.order"
                      :key="linked.node.linkCategory.id"
                      class="mt-5 "
                      md="6"
                      lg="4"
                      xl="3"
                      sm="12"
                    >
                      <b-card
                        class="carCat"
                        no-body
                        @click.stop.prevent="!selectCat ? (specialCategory(linked.node.linkCategory.type) ? catEspecial({ node: linked.node.linkCategory }) : getElement(linked.node.linkCategory.id, 'LIN')) : clickCheck(linked.node)"
                      >
                        <b-img
                          :src="buildImageUrl({ node: linked.node.linkCategory })"
                          class="card-img-top cursor-pointer"
                          img-alt="Card image"
                          fluid-grow
                          @error="errorImg"
                        />
                        <b-card-header>
                          <div id="acortarPadre">
                            <b-card-title
                              id="acortar"
                              class="mb-25"
                            >
                              <span
                                v-if="linked.node.linkCategory.state == 'GRE'"
                                class="mr-50 bullet bullet-success bullet-sm"
                              />
                              <span
                                v-if="linked.node.linkCategory.state == 'YEW'"
                                class="mr-50 bullet bullet-warning bullet-sm"
                              />
                              <span
                                v-if="linked.node.linkCategory.state == 'RED'"
                                class="mr-50 bullet bullet-danger bullet-sm"
                              />
                              <span
                                v-if="linked.node.linkCategory.state == 'BCK'"
                                class="mr-50 bullet bullet-dark bullet-sm"
                              />
                              {{
                                linked.node.linkCategory.name
                              }}
                            </b-card-title>
                            <b-card-text class="mb-0">
                              {{ $t('linkToCatg') }}
                            </b-card-text>
                          </div>
                        </b-card-header>

                        <b-card-body>
                          <div
                            v-if="!selectCat"
                            class="actions"
                          >
                            <b-dropdown
                              :text="linked.node.linkCategory.state == 'GRE' ? $t('Activada') : linked.node.linkCategory.state == 'RED' ? $t('Desactivada') : $t('Interna')"
                              :variant="linked.node.linkCategory.state == 'GRE' ? 'outline-success' : linked.node.linkCategory.state == 'RED' ? 'outline-danger' : 'outline-warning'"
                              class="p-2"
                              size="sm"
                            >
                              <b-dropdown-item @click.stop.prevent="setIsActive(linked.node.linkCategory, 'RED')">
                                🔴 {{ $t('Desactivada') }}
                              </b-dropdown-item>
                              <b-dropdown-item @click.stop.prevent="setIsActive(linked.node.linkCategory, 'YEW')">
                                🟡 {{ $t('Interna') }}
                              </b-dropdown-item>
                              <b-dropdown-item @click.stop.prevent="setIsActive(linked.node.linkCategory, 'GRE')">
                                🟢 {{ $t('Activada') }}
                              </b-dropdown-item>
                            </b-dropdown>
                            <div class="action">
                              <feather-icon
                                class="cursor-pointer"
                                icon="Edit2Icon"
                                size="20"
                                @click.stop.prevent="routeToEditView(linked.node.linkCategory.id)"
                              />
                              <b-dropdown
                                variant="link"
                                toggle-class="text-decoration-none"
                                no-caret
                                right
                              >
                                <template #button-content>
                                  <feather-icon
                                    color="white"
                                    icon="MoreVerticalIcon"
                                    size="20"
                                  />
                                </template>

                                <b-dropdown-item @click.stop.prevent="removeLink(linked)">
                                  <feather-icon
                                    icon="DeleteIcon"
                                    size="18"
                                  />
                                  {{ $t('dropLink') }}
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </div>
                          <div
                            v-else
                            class="actionsBar right"
                          >
                            <div class="action">
                              <b-form-checkbox
                                disabled
                                :value="linked.node.id"
                              />
                            </div>
                          </div>
                        </b-card-body>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </b-overlay>
            </b-form-checkbox-group>
          </b-tab>
          <!-- TABLE -->
          <b-tab>
            <template #title>
              <feather-icon icon="ListIcon" />
              <span> {{ $t("List") }}</span>
            </template>
            <b-card>
              <b-table-simple
                ref="refContentListTable"
                class="mt-2 mb-1"
                show-empty
                hover
                selectable
                :select-mode="single"
                :empty-text="$t('emptyText')"
                responsive
                primary-key="id"
                :no-border-collapse="true"
              >
                <b-thead>
                  <b-tr>
                    <b-th
                      v-for="i in tableColumns"
                      :key="i.key"
                    >
                      {{ i.label }}
                    </b-th>
                  </b-tr>
                </b-thead>
                <draggable
                  v-if="enlace"
                  :v-model="linkCategory"
                  tag="tbody"
                  handle=".handle"
                  :list="linkCategory"
                  group="categories"
                  :sort="orderEstructure"
                >
                  <b-tr
                    v-for="item in linkCategory "
                    :key="item.node.id"
                    @dragend="updateOrderTable"
                  >
                    <b-td @click.stop.prevent="!manual ? onRowSelected({ node: item.node.linkCategory }) : ''">
                      <div class="d-flex align-items-center">
                        <b-img
                          id="avatar"
                          class="mr-2 ml-2"
                          rounded="lg"
                          :src="buildImageUrl({ node: item.node.linkCategory })"
                          @error="errorImg"
                        />
                        <p>{{ item.node.linkCategory.name }}</p>
                      </div>
                    </b-td>

                    <b-td @click.stop.prevent="!manual ? onRowSelected({ node: item.node.linkCategory }) : ''">
                      {{ item.node.linkCategory.isFinal ? $t('Final') : $t('noFinal') }}
                    </b-td>
                    <b-td @click.stop.prevent="!manual ? onRowSelected({ node: item.node.linkCategory }) : ''">
                      {{ $t(categoryTypes[item.node.linkCategory.type]) }}
                    </b-td>
                    <b-td>
                      <i
                        v-if="manual"
                        class="fa fa-align-justify handle cursor-move text-warning"
                      />
                      <b-dropdown
                        v-else
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        right
                      >
                        <template #button-content>
                          <feather-icon
                            color="white"
                            icon="MoreVerticalIcon"
                            size="20"
                          />
                        </template>
                        <div>
                          <b-dropdown-item @click.stop.prevent="removeLink(item)">
                            <feather-icon
                              icon="DeleteIcon"
                              size="18"
                            />
                            {{ $t('dropLink') }}
                          </b-dropdown-item>
                        </div>
                      </b-dropdown>
                    </b-td>
                  </b-tr>
                </draggable>
                <draggable
                  v-else
                  :v-model="childCategory"
                  tag="tbody"
                  handle=".handle"
                  :list="childCategory"
                  group="categories"
                  :sort="orderEstructure"
                >
                  <b-tr
                    v-for="item in childCategory"
                    :key="item.node.id"
                    @dragend="updateOrderTable"
                  >
                    <b-td @click.stop.prevent="!manual ? onRowSelected(item) : ''">
                      <div
                        class="d-flex"
                        style="align-items: center"
                      >
                        <b-img
                          id="avatar"
                          class="mr-2 ml-2"
                          rounded="lg"
                          :src="buildImageUrl(item)"
                          @error="errorImg"
                        />
                        <p>{{ item.node.name }}</p>
                      </div>
                    </b-td>

                    <b-td @click.stop.prevent="!manual ? onRowSelected(item) : ''">
                      {{ item.node.isFinal ? $t('Final') : $t('noFinal') }}
                    </b-td>
                    <b-td @click.stop.prevent="!manual ? onRowSelected(item) : ''">
                      {{ $t(categoryTypes[item.node.type]) }}
                    </b-td>
                    <b-td>
                      <i
                        v-if="manual"
                        class="fa fa-align-justify handle cursor-move text-warning"
                      />
                      <b-dropdown
                        v-else
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        right
                      >
                        <template #button-content>
                          <feather-icon
                            color="white"
                            icon="MoreVerticalIcon"
                            size="20"
                          />
                        </template>
                        <div>
                          <b-dropdown-item
                            v-if="goToInit(item)"
                            @click.stop.prevent="updateParentToInit(item)"
                          >
                            <feather-icon
                              icon="ArrowUpIcon"
                              size="18"
                            />
                            {{ $t("contents.moveToInit") }}
                          </b-dropdown-item>
                          <b-dropdown-item @click.stop.prevent="routeToEditView(item.node.id, 0)">
                            <feather-icon
                              icon="Edit2Icon"
                              size="20"
                            />
                            {{ $t("contents.editCat") }}
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-if="!isSponsor"
                            @click.stop.prevent="showModal('moveToCat', item.node)"
                          >
                            <feather-icon
                              icon="CornerRightUpIcon"
                              size="18"
                            />
                            {{ $t("contents.moveToCat") }}
                          </b-dropdown-item>

                          <b-dropdown-item @click.stop.prevent="deleteCategory(item.node.id)">
                            <feather-icon
                              icon="DeleteIcon"
                              size="18"
                            />
                            {{ $t("contents.dropCat") }}
                          </b-dropdown-item>
                        </div>
                      </b-dropdown>
                    </b-td>
                  </b-tr>
                </draggable>
              </b-table-simple>
            </b-card>
          </b-tab>
        </b-tabs>
      </div>
    </b-overlay>
    <div>
      <div
        v-if="isLoading"
        class="overlay-container"
      >
        <b-overlay
          :show="overlay"
          variant="transparent"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        />
      </div>
      <div
        v-if="hasMore && !seeker"
        class="d-flex justify-content-center align-self-center "
        :style="isContent ? 'margin-top:50px' : ''"
      >
        <small v-if="(contentData.length == 0 && childCategory.length == 0 && linkCategory.length == 0) || isLoading">{{
          $t('load')
        }}...</small>
        <div v-else>
          <small v-if="isContent">{{ $t('downToSee') }}</small>
          <small v-else>{{ $t('downToSeeCat') }}</small><feather-icon
            icon="ArrowDownIcon"
            size="20"
            class="ml-50"
          />
        </div>
      </div>
    </div>

    <!-- MODAL PARA SELECCIONAR CATEGORÍA -->
    <b-modal
      ref="modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :type="false"
          :columns="categorySelectorTable"
          :exclude-ids="code ? (code + ',' + choosedCategory) : choosedCategory"
          :sponsor="isSponsor"
          :especial="isFiltered ? false : true"
          :enlace="isFiltered ? true : false"
          @confirm-selection="updateParentCategory"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="hideModal(1)"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <!-- MODAL PARA SELECCIONAR CATEGORÍA -->
    <b-modal
      ref="enlace"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :columns="categorySelectorTable"
          :exclude-ids="code ? (code + ',' + excludeIds) : excludeIds"
          :enlace="true"
          :sponsor="isSponsor"
          @confirm-selection="addLinkCategory"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="hideModal('enlace')"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <!-- MODAL PARA SELECCIONAR CONTENIDOS -->
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('code.sel')"
    >
      <div class="d-block text-center">
        <content-selector
          :exclude="!isPod"
          :sponsor="isSponsor"
          :multi="true"
          :podcast="isPod"
          @data="SelectContent"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="hideModal(0)"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <!-- MODAL PARA CREAR CATEGORÍA CONTENEDORA -->
    <b-modal
      id="modal-prevent-closing"
      ref="modal-cat"
      centered
      :title="$t('category.createCat')"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-alert show>
          <small>{{ $t('infoBox') }}</small>
        </b-alert>
        <b-form-group
          :label="$t('firstName')"
          label-for="name-input"
          :invalid-feedback="$t('required')"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>

    <!-- APARTADO DEMOS -->
    <!-- MODAL PARA SELECCIONAR PLATAFORMA -->
    <b-modal
      id="superuser"
      ref="superuser"
      hide-footer
      size="lg"
      title="Seleccionar plataforma"
    >
      <div class="d-block text-center">
        <platform-selector @client="getPlatform" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['superuser'].hide()"
      >
        {{ $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>
    <!-- MODAL PARA SELECCIONAR CONTENIDOS DE PLATAFORMAS -->
    <b-modal
      id="content-platform"
      ref="content-platform"
      hide-footer
      size="xl"
      :title="$t('code.sel')"
    >
      <div class="d-block text-center">
        <content-platform-selector
          :platform="platformId"
          @data="SelectContentId"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['content-platform'].hide()"
      >
        {{ $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>
    <div ref="infiniteScroll" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  BFormSelect,
  BFormSelectOption,
  BCol,
  BRow,
  BImg,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BButtonGroup,
  BDropdown,
  VBTooltip,
  BDropdownItem,
  BModal,
  BFormInput,
  BTabs,
  BTab,
  BFormCheckbox,
  BIcon,
  BCard,
  BBadge,
  BCardHeader,
  BCardTitle,
  BPagination,
  BAlert,
  BCardText,
  BFormGroup,
  BCardBody,
  BFormCheckboxGroup,
  BOverlay,
  BTableSimple,
  BTr,
  BThead,
  BTd,
  BTh,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError,
  getInitialCategoriesLimit, getContentsLimit, utf8ToB64, showToast,
  checkRelationOrder, moveToCategory, deactivateMenu, deactivateSlider, checkCategories, disabledAds,
  disabledCampaigns, isEmpty, specialCategory, checkMultiCategories, addContentsToCategory,
} from '@/store/functions'
import draggable from 'vuedraggable'
import { getUserData } from '@/auth/utils'
import configCategoryType from '@core/utils/categoryType'
import searchCategories from '@/views/contents/search/searchCat.vue'
import * as constants from '@core/utils/constants'
import PlatformSelector from '@/views/common/PlatformSelector.vue'
import ContentPlatformSelector from '@/views/common/ContentPlatformSelector.vue'
import ContentsTable from '../contents/ContentsListTable.vue'
import ContentsListCard from '../contents/ContentsListCard.vue'
import ContentSelector from '../../common/ContentSelector.vue'
import CategoriesAlerts from './CategoriesAlerts.vue'
import CategorySelector from '../../common/CategorySelector.vue'

const noCover = require('@/assets/images/backend/nocover.jpeg')
const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BFormSelect,
    ContentsListCard,
    ContentsTable,
    CategorySelector,
    ContentSelector,
    BImg,
    BTabs,
    BFormCheckboxGroup,
    BIcon,
    ContentPlatformSelector,
    BTab,
    BAlert,
    CategoriesAlerts,
    BBadge,
    BFormGroup,
    draggable,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BFormSelectOption,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardHeader,
    BCardTitle,
    BPagination,
    BCardText,
    BCardBody,
    BOverlay,
    PlatformSelector,
    BCol,
    BFormCheckbox,
    BModal,
    BRow,
    BFormInput,
    BTableSimple,
    BTr,
    BThead,
    BTd,
    BTh,
    searchCategories,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      hasNewOrder: false,
      reloadedOrder: false,
      rows: 0,
      excludeIds: '',
      selectCat: false,
      hiddeSearchInput: false,
      perPage: 30,
      scrollPage: 1,
      first: 30,
      specialCategory,
      currentPage: 1,
      userData: getUserData(),
      type: null,
      allCat: false,
      stateCat: null,
      parentCat: null,
      root: true,
      infoCategory: [],
      isFinal: null,
      orderBy: true,
      isPod: false,
      isFiltered: false,
      overlay: false,
      fromSyP: false,
      validSearch: false,
      isSponsor: false,
      isDevelopment: false,
      brItems: [
        {
          id: null,
          text: this.$t('contents.start'),
        },
      ],
      navItems: [{
        id: null,
        text: this.$t('contents.start'),
      }],
      single: 'single',
      categorySelectorTable: [
        { key: 'image', label: this.$t('zones.category') },
        { key: 'name', label: this.$t('administrators.name') },
        { key: 'type', label: this.$t('code.type') },
      ],
      choosedCategory: null,
      orderEstructure: true,
      seleccionar: false,
      todos: false,
      state: null,
      optionsStatus: [
        { value: null, text: this.$t('editContent.status') },
        { value: 'RED', text: `🔴 ${this.$t('ecommerce.NoPublic')}` },
        { value: 'YEW', text: `🟡 ${this.$t('dataGeneric.internal')}` },
        { value: 'GRE', text: `🟢 ${this.$t('ecommerce.public')}` },
      ],
      statusCat: null,
      choicesContents: [],
      infoContent: [],
      showAlert: false,
      showAlertContent: false,
      totalContents: 0,
      feedPodcast: '',
      totalCategories: 0,
      tableColumns: [
        { key: 'name', label: this.$t('message.tableHeader.name') },
        { key: 'is_final', label: this.$t('contents.finalCategory') },
        { key: 'type', label: this.$t('contents.categoryType') },
        { key: 'actions', label: this.$t('resources.tableHeader.actions') },
      ],
      categoryTypes: configCategoryType(true, false, false, false),
      categoryType: null,
      search: '',
      searchInput: '',
      seeker: false,
      isContent: false,
      idParent: null,
      actions: null,
      tabIndex: 0,
      manual: false,
      name: '',
      headers: {},
      nameState: null,
      enlace: false,
      link: false,
      idEnlace: null,
      linkCategory: [],
      selectCategory: [],
      lastOrder: 0,
      orderType: 'MAN',
      orderList: {
        MAN: 'Manual',
        EAS: this.$t('asct'),
        ALF: this.$t('alf'),
        EDE: this.$t('desct'),
      },
      childCategory: [],
      contentData: [],
      code: this.$route.params.id != null ? this.$route.params.id : null,
      platformId: '',
      isLoading: false,
      hasMore: true,
      searchInputPlaceholder: this.$t('Category'),
    }
  },
  watch: {
    seleccionar() {
      if (!this.seleccionar) {
        this.choicesContents = []
        this.infoContent = []
      }
    },
    selectCat() {
      this.selectCategory = []
      this.infoCategory = []
      this.statusCat = null
      this.allCat = false
    },
    allCat() {
      if (!this.seeker) {
        const arrayCategories = this.enlace ? this.linkCategory.slice() : this.childCategory.slice()
        if (this.allCat) {
          arrayCategories.forEach(element => {
            this.selectCategory.push(element.node.id)
            this.infoCategory.push(element.node)
          })
        } else {
          this.selectCategory = []
          this.infoCategory = []
        }
      }
    },
    isFinal() {
      this.currentPage = 1
    },
    stateCat() {
      this.currentPage = 1
    },
    parentCat() {
      this.currentPage = 1
    },
    isSponsor() {
      this.currentPage = 1
    },
    type() {
      this.currentPage = 1
    },
    totalCategories() {
      if (!this.isSponsor && this.code != null && this.totalCategories === 0 && !this.seeker) {
        axios
          .post('', {
            query: `
        mutation{
        updateCategory(id:"${this.code}",input:{isActive:false,state: RED}) {
          category {
              id
              type
          }
        }
      }
        `,
          })
          .then(result => {
            messageError(result, this)

            this.$swal({
              title: this.$t('warning'),
              text: this.$t('advertencia'),
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: this.$t('next'),
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(() => {
              showToast(this.$t('success'), 1, this)
              this.deactivatedMenu()
            })
          })
          .catch(() => { })
      } else if (this.isSponsor && this.code != null && this.totalCategories === 0 && !this.seeker) {
        axios
          .post('', {
            query: `{
                  allCategories(id:"${this.code}") {
                    edges {
                      node {
                        id
                        childCategories {
                          totalCount
                          edges {
                            node {
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }`,
          }).then(result => {
            messageError(result, this)

            if (result.data.data.allCategories.edges[0].node.childCategories.totalCount === 0) {
              axios
                .post('', {
                  query: `
                    mutation{
                    updateCategory(id:"${this.code}",input:{isActive:false,state: RED}) {
                      category {
                          id
                          type
                      }
                    }
                  }
                  `,
                })
                .then(res => {
                  messageError(res, this)

                  this.$swal({
                    title: this.$t('warning'),
                    text: this.$t('advertencia'),
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: this.$t('next'),
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then(() => {
                    showToast(this.$t('success'), 1, this)
                    this.deactivatedMenu()
                  })
                })
                .catch(() => { })
            }
          }).catch(() => {

          })
      }
    },
    totalContents() {
      if (!this.isSponsor && this.totalContents === 0 && !this.seeker) {
        axios
          .post('', {
            query: `
        mutation{
        updateCategory(id:"${this.code}",input:{isActive:false,state: RED}) {
          category {
              id
              type
          }
        }
      }
        `,
          })
          .then(result => {
            messageError(result, this)

            this.$swal({
              title: this.$t('warning'),
              text: this.$t('advertenciaCont'),
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: this.$t('next'),
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(() => {
              showToast(this.$t('success'), 1, this)
              this.deactivatedMenu()
            })
          })
          .catch(() => { })
      } else if (this.isSponsor && this.totalContents === 0 && !this.seeker) {
        axios
          .post('', {
            query: `{
                  allCategories(id:"${this.code}") {
                    edges {
                      node {
                        id
                        categoryContent {
                          totalCount
                          edges {
                            node {
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }`,
          }).then(result => {
            messageError(result, this)

            if (result.data.data.allCategories.edges[0].node.categoryContent.totalCount === 0) {
              axios
                .post('', {
                  query: `
                      mutation{
                      updateCategory(id:"${this.code}",input:{isActive:false,state: RED}) {
                        category {
                            id
                            type
                        }
                      }
                    }
                      `,
                })
                .then(res => {
                  messageError(res, this)

                  this.$swal({
                    title: this.$t('warning'),
                    text: this.$t('advertenciaCont'),
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: this.$t('next'),
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then(() => {
                    showToast(this.$t('success'), 1, this)
                    this.deactivatedMenu()
                  })
                })
                .catch(() => { })
            }
          }).catch(() => {

          })
      }
    },
    /* LIMPIAR SELECCIÓN DE TODOS LOS CONTENIDOS AL CAMBIAR DE TABS */
    tabIndex() {
      this.todos = false
      /* TODO Por ahora se limpiara, pero más adelante mantendrá los contenidos y en el modo lista se podrán seleccionar también */
      this.choicesContents = []
      this.infoContent = []
    },
    /* OBTENER SI SE TRATA UNA CATEGORÍA DE ENLACES */
  },
  computed: {
    ...mapGetters('app', ['sizeWindow']),
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)

    const mode = process.env.VUE_APP_MODE ? process.env.VUE_APP_MODE : process.env.NODE_ENV
    if (mode !== 'production') {
      this.isDevelopment = true
    } else {
      this.isDevelopment = false
    }
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }

    if (this.userData.groups && this.userData.groups.edges.length !== 0) {
      this.isSponsor = this.userData.groups.edges[0].node.name === constants.sponsor
    }

    if (this.$route.params.link === 'LIN') {
      this.link = true
      if (this.$route.name === 'link-categories') {
        this.idEnlace = this.code
        this.enlace = true
      } else {
        this.enlace = false
        this.idEnlace = this.$route.params.parent
      }
    }
    this.handleScroll()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const element = this.$refs.infiniteScroll
      const rect = element.getBoundingClientRect()
      if (rect.top <= window.innerHeight && this.hasMore && !this.isLoading) {
        if (this.code != null) { this.fetchParentData(this.code) } else this.fetchRootData()
        this.overlay = true
        this.isLoading = true
      }
    },
    getSelection(data) {
      this.selectCategory = data[0].slice()
      this.infoCategory = data[1].slice()
    },
    isEmpty,
    onKeyUp(event, click = false) {
      const keycode = event.keyCode || event.which
      if (keycode === 13 || click) {
        this.currentPage = 1
        if (this.search !== this.searchInput) { this.hiddeSearchInput = true }
        this.search = this.searchInput
      }
    },
    searching() {
      document.getElementById('search').classList.add('searching')
      if (!this.seeker) { this.hiddeSearchInput = true }
      this.searchInputPlaceholder = this.$t('searchCat')
      this.seeker = true
    },
    cleanSearch() {
      document.getElementById('search').classList.remove('searching')
      this.type = null
      this.isFinal = null
      this.stateCat = null
      this.parentCat = null
      this.seeker = false
      this.selectCat = false
      this.statusCat = null
      this.search = ''
      this.searchInput = ''
      this.searchInputPlaceholder = this.$t('Category')
      this.updateView()
    },
    deactivatedMenu() {
      const query = `{
        allMenu (category:"${utf8ToB64(`id:${this.code}`)}"){
          edges {
            node {
              id
              isActive
              category {
                id
              }
            }
          }
        }
      }`
      axios
        .post('', {
          query,
        }).then(result => {
          messageError(result, this)

          const res = result.data.data.allMenu.edges
          let notMutation = 0
          if (res.length !== 0) {
            let mutation = 'mutation{'
            res.forEach((element, i) => {
              if (element.node.isActive) {
                mutation += `menu_${i + 1}: updateMenu(id:"${element.node.id}",input:{isActive:false}){
                  menu{
                    id
                    isActive
                  }
                }`
              } else {
                notMutation += 1
              }
            })
            mutation += '}'
            if (notMutation < res.length) {
              axios
                .post('', {
                  query: mutation,
                }).then(resMut => {
                  messageError(resMut, this).then(r => {
                    if (!r) { showToast(this.$t('success'), 1, this) }
                  }).catch(() => {

                  })
                }).catch(err => {
                  console.log(err)
                })
            }
          }
        }).catch(err => {
          console.log(err)
        })
    },
    getCheckCat(category) {
      const { type } = category
      if (type === 'POD') {
        this.isPod = true
      } else {
        this.isPod = false
      }
      if (type === 'LIN') {
        this.isFiltered = true
      } else {
        this.isFiltered = false
      }
    },
    /* LLAMADA DE FUNCIONES DE ORDENACIÓN SEGÚN EL TIPO DE ORDENACIÓN SELECCIONADA */
    ordenar(type) {
      this.orderType = type

      switch (this.orderType) {
        case 'EAS':
          this.sortedCategory(this.orderType)
          break
        case 'EDE':
          this.sortedCategory(this.orderType)
          break
        case 'ALF':
          this.sortedCategory(this.orderType)
          break
        case 'MAN':
          this.manual = !this.manual
          if (!this.manual) this.updateView()
          break
        default:
          break
      }
    },
    /* MÉTODO PARA CAMBIAR EL TIPO DE ORDEN DE LA CATEGORÍA SEGÚN EL ORDEN SELECCIONADO: MAN,EDE,EAS */
    changeTypeOrder() {
      try {
        if (!isEmpty(this.contentData)) {
          axios
            .post('', {
              query: `
            mutation{
            updateCategory(id:"${this.code}",input:{orderType:${this.orderType}})  {
              category {
                id
              }
            }
            }
           `,
            }).then(result => {
              messageError(result, this)
            })
        }
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    SelectContentId(data) {
      /* Mutation para añadir contenidos de otra categoría */
      const arrayContents = []
      /* TODO */
      axios
        .post('', {
          query: `
            query{  
              allCategories(
              id:"${this.code}"
              ){
              edges{
                node{
                  categoryContent(orderBy:"order,-created_at") {
                    edges {
                      node {
                        id
                        order
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        }).then(result => {
          messageError(result, this)
          const { categoryContent } = result.data.data.allCategories.edges[0].node
          categoryContent.edges.forEach(element => {
            arrayContents.push(element.node.id)
          })
          arrayContents.unshift(...data)
          const query = `      mutation($id: ID!,$contents: [ID]){
        updateCategory(id: $id,input:{categoryContent:$contents}){                
          category{
            id
            name
          }
        }
      }`
          axios
            .post('', {
              variables: {
                id: this.code,
                contents: arrayContents,
              },
              query,
            }).then(res => {
              messageError(res, this)
              showToast(this.$t('success'), 1, this)
              this.reloadData(this.code)
            }).catch(() => {
              showToast(this.$t('error'), 2, this)
            })
          this.$refs['content-platform'].hide()
        }).catch(error => {
          console.log(error)
        })
    },
    /* MÉTODO PARA ORDENAR ALFABÉTICAMENTE */
    sortedCategory(orderType) {
      axios
        .post('', {
          query: `
              mutation{
                sortedContents(categoryId:"${this.code}",orderType:"${orderType}"){
                  okCode
                }
              }
           `,
        }).then(result => {
          messageError(result, this)
          if (result.data.data.sortedContents.okCode === 2) {
            this.$swal({
              title: this.$t('noEmitionDate'),
              text: this.$t('infoEmit'),
              icon: 'warning',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(() => {

            }).catch(() => {

            })
            showToast(this.$t('success'), 1, this)
          }
          if (result.data.data.sortedContents.okCode === 1) showToast(this.$t('success'), 1, this)
          this.reloadData(this.code)
        }).catch(() => {
          showToast(this.$t('error'), 0, this)
        })
    },

    /* METODO PARA REALIZAR LAS LLAMADAS DE LOS DISTINTOS MODALES SEGÚN SE NECESITE */
    showModal(type, node) {
      switch (type) {
        case 'addContent':
          this.$refs['my-modal'].show()
          break
        case 'superuser':
          this.$refs.superuser.show()
          break

        case 'catgCont':
          this.$refs['modal-cat'].show()
          break
        case 'enlace':
          this.$refs.enlace.show()
          break
        default:
          if (node.type === 'LIN') {
            this.isFiltered = true
          } else {
            this.isFiltered = false
          }
          this.choosedCategory = isEmpty(node.id) ? '' : node.id
          this.$refs.modal.show()
          break
      }
    },
    /* METODO PARA CERRAR LOS MODALES SEGÚN SELECCIÓN */
    hideModal(type) {
      switch (type) {
        case 0:
          this.$refs['my-modal'].hide()
          break
        case 'enlace':
          this.$refs.enlace.hide()
          break
        case 'cat':
          break
        default:
          this.$refs.modal.hide()
          break
      }
    },
    updateView() {
      if (!this.seeker) {
        if (this.code != null) { this.reloadData(this.code) } else this.fetchRootData(true)
      } else {
        this.$refs.searchCat.getCategories()
      }
    },
    /* RESPUESTA DEL MODAL DE CONTENIDOS */
    SelectContent(data) {
      const contentsSelected = data
      this.hideModal(0)
      addContentsToCategory(this.code, contentsSelected).then(() => {
        showToast(this.$t('success'), 1, this)
        checkRelationOrder(this.code).then(() => {
          this.reloadData(this.code)
        }).catch(err => {
          console.log(err)
        })
      }).catch(err => {
        showToast(this.$t('error'), 2, this)
        console.log(err)
      })
    },
    chooseCategory(event) {
      this.choosedCategory = this.childCategory[event.oldIndex]
    },
    getPlatform(data) {
      this.platformId = data.id
      this.$refs.superuser.hide()
      this.$refs['content-platform'].show()
    },
    /* MÉTODO PARA LLAMAR A LAS CATEGORÍAS ROOT DEL MENÚ INICIO */
    fetchRootData(reload = false) {
      this.overlay = true
      this.isLoading = true
      this.seleccionar = false
      this.todos = false
      const { scrollPage } = this
      const { scrollY } = window
      let actualPage = scrollPage
      if (reload) {
        this.childCategory = []
        actualPage = 1
      }
      this.getRootOffset(reload, scrollPage, actualPage, scrollY)
    },
    getRootOffset(reload, scrollPage, actualPage, scroll) {
      const { headers, first } = this

      axios
        .post('', {
          query: `
          query {
            allCategories(client:"${this.userData.profile.client.id}",
            orderBy:"order",
            ${first !== 0 ? `first: ${first}` : ''}, 
            offset: ${first * (actualPage - 1)},
            ${this.isSponsor ? `sponsor:"${utf8ToB64(`id:${this.userData.id}`)}"` : 'parent_Isnull: true'}
            )  {
              pageInfo {
            hasNextPage            
            }
            totalCount
                    edges {
                      node {
                        id
                        name
                        categoryMenu {
                          totalCount                    
                        }        
                        categorySlide {
                          totalCount                                
                        }
                        orderType
                        order                        
                        image
                        imageUrl
                        mediaLocation       
                        isFinal                        
                        isActive
                        state
                        type       
                        parent {
                          id
                          name 
                          isFinal
                        }                 
                      }
                  }
              }
          }
          
        `,
        }, { headers })
        .then(response => {
          messageError(response, this)
          const { allCategories } = response.data.data
          this.totalCategories = allCategories.totalCount
          const srch = allCategories.edges
          const { result } = getInitialCategoriesLimit(
            this.totalCategories,
          )
          this.showAlert = result
          this.childCategory = [...this.childCategory, ...srch]
          if (!reload) this.scrollPage += 1
          this.hasMore = allCategories.pageInfo.hasNextPage

          if (actualPage < (scrollPage - 1)) {
            this.getRootOffset(reload, scrollPage, actualPage + 1, scroll)
          } else {
            if (scroll !== null) {
              window.scrollTo(0, scroll)
            }
            this.overlay = false
            this.isLoading = false
            this.contentData = []
          }
        }).catch(err => {
          console.log(err)
        })
    },
    onCopy() {
      showToast(this.$t('code.tCopy'), 1, this)
    },
    onError() {
      showToast(this.$t('code.errorCopy'), 2, this)
    },
    generateXml(idCategory) {
      const mode = process.env.VUE_APP_MODE ? process.env.VUE_APP_MODE : process.env.NODE_ENV
      let host = ''
      switch (mode) {
        case 'develop':
          host = 'https://develop.interactvty.com'
          break
        case 'sandbox':
          host = 'https://sandbox.interactvty.com'
          break
        case 'production':
          host = 'https://api.interactvty.com'
          break
        default:
          host = 'http://localhost:8000'
          break
      }

      const clientName = this.userData.profile.client.name
      this.feedPodcast = `${host}/api/2.0/contents/feed_podcasts/?category_id=${idCategory}&client=${clientName}`
    },

    /* MÉTODO METER CATEGORÍA DESDE MODAL EN CATEGORÍA ACTUAL */
    updateParentCategory(item) {
      this.hideModal(1)
      if (this.selectCat) {
        this.setMoveCat(item)
      } else {
        const childCategories = []
        item.node.childCategories.edges.forEach(element => {
          if (element.node.id !== this.choosedCategory) {
            childCategories.push(element.node.id)
          }
        })
        childCategories.push(this.choosedCategory)

        let lastOrder = 0
        if (item.node.childCategories.edges.length > 0) {
          lastOrder = item.node.childCategories.edges.length + 1
        } else {
          lastOrder = 0
        }
        axios
          .post('', {
            query: `
          mutation{
            updateCategory(id: "${this.choosedCategory}", input:{
             parent: "${item === null ? null : item.node.id}",
             order: ${lastOrder}
            }) {
              category{
                id
                orderType
                name               
                                
              }
            }
          }
        `,
          })
          .then(res => {
            messageError(res, this)

            this.childCategory = this.childCategory.filter(
              elem => elem.node.id !== this.choosedCategory,
            )
            this.totalCategories = this.childCategory.length
            showToast(this.$t('success'), 1, this)

            this.updateView()
          })
          .catch(error => {
            showToast(this.$t('error'), 2, this)

            console.log(error)
          })
      }
    },

    /* MÉTODO PARA CREAR NUEVAS CATEGORÍAS */
    createNewCategory() {
      try {
        const { result } = getInitialCategoriesLimit(this.childCategory.length)
        if (result) {
          this.showAlert = true
        } else if (this.code) {
          this.$router.push({ name: 'subCategory-create', params: { id: this.code, order: this.totalCategories } })
        } else {
          this.$router.push({ name: 'category-create', params: { order: this.totalCategories } })
        }
      } catch (error) {
        if (this.code) {
          this.$router.push({ name: 'subCategory-create', params: { id: this.code, order: this.totalCategories } })
        } else {
          this.$router.push({ name: 'category-create', params: { order: this.totalCategories } })
        }
      }
    },
    /* MÉTODO PARA CREAR NUEVOS CONTENIDOS */
    createNewContent() {
      let total = 0
      axios
        .post('', {
          query: `
          query {
            allContents(client:"${this.userData.profile.client.id}") {
              totalCount
            }
          }
        `,
        })
        .then(response => {
          messageError(response, this)

          total = response.data.data.allContents.totalCount
        }).catch(error => {
          console.log(error)
        })

      const { result } = getContentsLimit(total)
      if (result) this.showAlertContent = true
      else {
        this.$router.push({
          name: 'contents-create',
          params: { id: this.code },
        })
      }
    },
    /* FUNCIÓN PARA INSERTAR IMAGEN DE ERROR EN CASO DE QUE UNA IMÁGEN DE ERROR */
    errorImg(e) {
      e.target.src = fileError
    },
    /* MÉTODO PARA ELIMINAR CATEGORÍAS */
    deleteCategory(id) {
      this.overlay = true
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          checkCategories(id).then(infoCategorie => {
            axios
              .post('', {
                query: `
                  mutation{
                    deleteCategory(id:"${id}") {
                      found
                      deletedId
                    }
                  }`,
              })
              .then(res => {
                messageError(res, this)

                if (this.isSponsor) {
                  if (infoCategorie.campaign.length !== 0) {
                    disabledCampaigns(infoCategorie.campaign, id).then(() => {
                    }).catch(() => {

                    })
                  }
                  if (infoCategorie.ad.length !== 0) {
                    disabledAds(infoCategorie.ad, id).then(() => {

                    }).catch(error => {
                      console.log(error)
                    })
                  }
                }
                this.overlay = false
                showToast(this.$t('success'), 1, this)
                this.updateView()
              })
              .catch(res => {
                this.overlay = false
                showToast(this.$t('error'), 2, this)
                console.log(res)
              })
          }).catch(() => {

          })
        } else {
          this.overlay = false
        }
      })
    },
    /* LLAMADA A LA RUTA DE EDICIÓN DE CATEGORÍAS */
    routeToEditView(categoryId) {
      this.$router.push({ name: 'category-edit', params: { id: categoryId } })
    },
    /* CLICK EN FILA DE TABLA */
    onRowSelected(items) {
      if (items.length !== 0) {
        if (specialCategory(items.node.type)) { this.catEspecial(items) } else { this.getElement(items.node.id, this.link ? this.$route.params.link : items.node.type) }
      }
    },
    buildImageUrl(folder) {
      return folder.node.imageUrl == null || folder.node.imageUrl.length === 0
        ? noCover
        : folder.node.imageUrl
    },
    updateOrderTable() {
      this.overlay = true
      const arrayNum = []
      const arrayId = []

      const data = this.enlace === false ? this.childCategory : this.linkCategory
      for (let i = 0; i < data.length; i += 1) {
        arrayId.push(data[i].node.id)
        arrayNum.push(data[i].node.order)
      }

      arrayNum.sort((a, b) => a - b)

      const dataArr = new Set(arrayNum)

      const result = [...dataArr]
      while (result.length !== arrayNum.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push((n + 1).toString())
      }

      let query = ` mutation
      {`

      for (let i = 0; i < result.length; i += 1) {
        if (this.enlace) {
          query += `
              m${i + 1}: updateLinkCategory(id:"${arrayId[i]}",input:{ order: ${result[i]
}}){
                          linkCategory{
                          id
                          order
                          }
                        }
            `
        } else {
          query += `
          m${i + 1}: updateCategory(id:"${arrayId[i]}",input:{ order: ${result[i]
}}){
                      category{
                        id
                        name
                        order
                      }
                    }
                  `
        }
      }
      query += `
      }`
      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          this.changeTypeOrder()
          this.overlay = false
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 0, this)
          this.overlay = false
        })
    },
    updateOrder(event) {
      this.overlay = true
      const arrayNum = []
      const arrayId = []
      const evento = event.target.parentNode.childNodes
      for (let i = 0; i < evento.length; i += 1) {
        if (evento[i]) {
          arrayId.push(evento[i].id.split(' ')[0])
          arrayNum.push(evento[i].id.split(' ')[1])
        }
      }
      arrayNum.sort((a, b) => a - b)

      const dataArr = new Set(arrayNum)

      const result = [...dataArr]
      while (result.length !== arrayNum.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push((n + 1).toString())
      }

      let query = ` mutation
      {`

      for (let i = 0; i < result.length; i += 1) {
        query += `
        m${i + 1}: updateCategory(id:"${arrayId[i]}",input:{ order: ${result[i]
}}){
                    category{
                      id
                      name
                      order
                    }
                  }
                `
      }
      query += `
      }`

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          this.changeTypeOrder()
          this.overlay = false
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 0, this)
          this.overlay = false
        })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      this.overlay = true
      this.createSubcategory(this.contentData)
    },
    createSubcategory() {
      const query = `
          mutation{
            createCategory(input:{name:"${this.name}",client:"${this.userData.profile.client.id}",parent:"${this.code}",
            isFinal:true,orderType:${this.orderType},isActive:false,state: RED,type:${this.categoryType}
              ${this.isSponsor ? `,creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}
            }){
              category {
                id
                name
              }
            }
            updateCategory(id:"${this.code}",input:{isFinal:false,orderType:MAN})
                {
                    category{                     
                  id
                  name
                  order
                  orderType
                  image
                  imageUrl
                  mediaLocation
                  isFinal
                  type
                  parent {
                    id
                    name
                    description
                    isFinal
                  }
                }
                }
          }`
      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)
          showToast(this.$t('success'), 1, this)

          moveToCategory(this.code, res.data.data.createCategory.category.id).then(result => {
            messageError(result, this)
            this.reloadData(this.code)
            showToast(this.$t('success'), 1, this)
          }).catch(err => {
            console.log(err)
            showToast(this.$t('error'), 0, this)
          })
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
          })
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 0, this)
        })
    },

    addLinkCategory(item) {
      const order = this.lastOrder
      this.hideModal('enlace')
      axios
        .post('', {
          query: `
          mutation{
             createLinkCategory(input:{parentCategory:"${this.code}",linkCategory:"${item === null ? null : item.node.id}",order:${order}}){
              linkCategory{
                id
                order
                
              }
            }
          }`,
        }).then(res => {
          messageError(res, this)
          this.reloadData(this.code)
        }).catch(res => {
          console.log(res)
        })
    },
    catEspecial(cat) {
      this.$swal({
        title: this.$t('specialCategoryTitle'),
        text: this.$t('specialCategoryMsg', { category_name: cat.node.name, category_type: this.$t(this.categoryTypes[cat.node.type]) }),
        icon: 'info',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {

      }).catch(() => {

      })
    },
    fetchParentData(id) {
      const { headers } = this
      this.isLoading = true
      this.overlay = true
      this.todos = false
      this.code = id
      const { scrollPage } = this

      if (scrollPage === 1) {
        axios
          .post('', {
            query: `
          query {
            allCategories(id:"${this.code}",client:"${this.userData.profile.client.id}")  {
            totalCount
                    edges {
                      node {
                        id
                        sponsor {
                          edges {
                            node {
                              id
                            }
                          }
                        }
                        creator {
                          id
                        }
                        isActive
                        state
                        name
                        orderType
                        isFinal
                        type                        
                        parent {
                          id
                          name                          
                          type

                        }   
                      }
                  }
              }
          }          
        `,
          }, { headers }).then(result => {
            messageError(result, this)
            const actualCategory = result.data.data.allCategories.edges[0].node
            let authorized = true
            if (this.isSponsor) {
              authorized = actualCategory.sponsor.edges.some(spon => spon.node.id === this.userData.id)
              || (actualCategory.creator && actualCategory.creator.id === this.userData.id)
            }
            if (!authorized) {
              const parentId = actualCategory.parent?.id

              if (parentId) {
                this.checkSponsorIdOnParent(parentId)
                  .then(res => (res ? this.loadCategory(actualCategory, id) : this.$router.push({ name: 'misc-not-authorized' })))
                  .catch(err => console.log(err))
              } else {
                this.$router.push({ name: 'misc-not-authorized' })
              }
            } else {
              this.loadCategory(actualCategory, id)
            }
          }).catch(err => {
            console.log(err)
            this.overlay = false
          })
      } else if (this.isContent) {
        this.getContents(this.hasNewOrder)
      } else if (this.enlace) {
        this.getLinkCategories()
      } else {
        this.getInfoCategory()
      }
    },
    loadCategory(actualCategory, id) {
      this.isContent = actualCategory.isFinal
      if (this.isContent) this.getCheckCat(actualCategory)
      this.orderType = actualCategory.orderType
      this.categoryType = actualCategory.type
      this.enlace = actualCategory.type === 'LIN'

      this.idParent = actualCategory.parent != null ? actualCategory.parent.id : null
      this.fromSyP = actualCategory.parent != null ? !!(actualCategory.parent.type === 'SER' || actualCategory.parent.type === 'PRO') : false
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      this.brItems.push({
        text: actualCategory.name,
        id: actualCategory.id,
      })
      if (this.link) {
        this.navigationLink(id)
      } else if (this.idParent != null) { this.recursive(id) } else this.navItems = this.brItems.slice()

      this.getInfoCategory()
    },
    getInfoCategory() {
      const { headers } = this
      this.isLoading = true
      this.overlay = true
      this.todos = false
      const { first } = this
      const { scrollPage } = this
      axios
        .post('', {
          query: `
          query {
            allCategories(id:"${this.code}",client:"${this.userData.profile.client.id}")  {
            totalCount
                    edges {
                      node {                        
                        childCategories(
                        first:${first},
                        offset:${first * (scrollPage - 1)},
                        orderBy:"order"){
                          pageInfo {
                            hasNextPage                            
                          }
                           totalCount
                            edgeCount                            
                            edges {
                              node {
                              id                              
                              name
                              categoryMenu {
                                totalCount                    
                              }
                              categorySlide {
                                  totalCount                                
                              }
                              order                        
                              image
                              imageUrl
                              mediaLocation
                              isFinal                        
                              isActive
                              state
                              type
                                parent {
                                  id
                                  name                    
                                  isFinal
                                  type
                                }   
                              }
                            }
                          }
                        categoryContentOrder {
                          totalCount
                        }
                        categoryContent{
                           totalCount                          
                        }            
                      }
                  }
              }
          }          
        `,
        }, { headers }).then(result => {
          messageError(result, this)
          const actualCategory = result.data.data.allCategories.edges[0].node

          if (this.isContent) {
            const newOrder = actualCategory.categoryContentOrder.totalCount
            const oldOrder = actualCategory.categoryContent.totalCount
            this.hasNewOrder = newOrder !== 0
            if (this.hasNewOrder && !this.reloadedOrder && (oldOrder !== newOrder)) {
              this.reloadedOrder = true
              checkRelationOrder(this.code).then(() => {
                this.reloadData(this.code)
              }).catch(err => {
                console.log(err)
              })
            } else {
              this.getContents(this.hasNewOrder)
            }
          } else if (this.enlace) {
            this.getLinkCategories()
          } else {
            this.totalCategories = actualCategory.childCategories.totalCount
            const srch = actualCategory.childCategories.edges
            this.childCategory = [...this.childCategory, ...srch]
            this.scrollPage += 1
            this.hasMore = actualCategory.childCategories.pageInfo.hasNextPage

            this.contentData = []
            this.isLoading = false
            this.overlay = false
          }
        }).catch(err => {
          console.log(err)
          this.overlay = false
        })
    },
    getContents(newOrder = true, reload = false) {
      this.overlay = true
      this.isLoading = true
      const { scrollPage } = this
      const { scrollY } = window
      let actualPage = scrollPage
      if (reload) {
        this.contentData = []
        actualPage = 1
      }
      this.getContentsOffset(newOrder, reload, scrollPage, actualPage, scrollY)
    },
    getContentsOffset(newOrder, reload, scrollPage, actualPage, scrollY) {
      const { headers, first } = this
      const encondedCode = utf8ToB64(`id:${this.code}`)

      const query = `{            
            relationCategoryContents(
            ${newOrder ? 'orderBy: "content_content_order__order"' : ''}, 
            categoryId: "${this.code}", 
            ${first !== 0 ? `first: ${first}` : ''}, 
            offset: ${first * (actualPage - 1)},
            ) {
              pageInfo {
                hasNextPage
              }
              totalCount
              edges {
                node {
                  id
                  name  
                  order
                  type      
                  contentSlide {
                    totalCount                                
                  }                       
                  emisionDate
                  modifiedAt
                  publishDate
                  expirationDate  
                  isActive
                  order
                  state
                  image
                  imageUrl
                  categories {
                  edges {
                    node {
                      id
                    }
                  }
                }
                contentContentOrder(category:"${encondedCode}") {
                    totalCount
                    edges {
                      node {
                        id
                        order
                        category {
                          id
                          name
                        }
                      }
                    }
                  }
                  contentResources(isInitial:true){
                    edges{
                      node{
                        resource{
                          id
                          typeStream
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          `
      axios
        .post('', {
          query,
        }, { headers }).then(result => {
          messageError(result, this)
          const contents = result.data.data.relationCategoryContents
          this.totalContents = contents.totalCount
          const srch = contents.edges

          this.contentData = [...this.contentData, ...srch]
          if (!reload) this.scrollPage += 1

          this.hasMore = contents.pageInfo.hasNextPage
          if (actualPage < (scrollPage - 1)) {
            this.getContentsOffset(newOrder, reload, scrollPage, actualPage + 1, scrollY)
          } else {
            if (scrollY !== null) {
              window.scrollTo(0, scrollY)
            }
            this.isLoading = false
            this.overlay = false
          }
        }).catch(err => {
          this.overlay = false

          console.log(err)
        })
    },
    setStatusCat() {
      let deactivate = false
      let arrayCat = ''
      let isActive = false
      let active = false
      switch (this.statusCat) {
        case 'GRE':
          isActive = true
          active = true
          break
        case 'YEW':
          isActive = true
          active = false
          break

        default:
          isActive = false
          active = false

          break
      }
      const idsCategories = []
      if (!this.enlace || this.seeker) {
        this.infoCategory.forEach(element => {
          idsCategories.push(element.id)
          if ((element.categoryMenu.totalCount !== 0 || element.categorySlide.totalCount !== 0) && !active) {
            if (arrayCat) {
              arrayCat += ', '
            }
            arrayCat += element.name
            deactivate = true
          }
        })
      } else {
        this.infoCategory.forEach(element => {
          idsCategories.push(element.linkCategory.id)

          if ((element.linkCategory.categoryMenu.totalCount !== 0 || element.linkCategory.categorySlide.totalCount !== 0) && !active) {
            if (arrayCat) {
              arrayCat += ', '
            }
            arrayCat += element.linkCategory.name
            deactivate = true
          }
        })
      }

      if (deactivate) {
        this.$swal({
          title: this.$t('dataGeneric.atention'),
          text: this.$t('descCat', { arrayCat }),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('next'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            let query = 'mutation{'
            idsCategories.forEach((element, i) => {
              query += `
                  m${i + 1}: updateCategory(id: "${element}", input: {
                    state: ${this.statusCat},
                    isActive:${isActive}  }                 
                  
                  ) {
                    category {
                          id
                          isActive
                        }
                      }
                      `
            })
            query += '}'
            axios
              .post('', {
                query,
              })
              .then(res => {
                messageError(res, this)
                showToast(this.$t('code.updateData'), 1, this)
                deactivateMenu(idsCategories).then(() => {
                }).catch(err => {
                  console.log(err)
                })
                deactivateSlider(idsCategories).then(() => {

                }).catch(err => {
                  console.log(err)
                })
                if (!this.seeker) {
                  if (this.enlace) {
                    idsCategories.forEach(idCat => {
                      const indice = this.linkCategory.findIndex(objeto => objeto.node.linkCategory.id === idCat)
                      if (indice !== -1) {
                        this.linkCategory[indice].node.linkCategory.state = this.statusCat
                      }
                    })
                  } else {
                    idsCategories.forEach(idCat => {
                      const indice = this.childCategory.findIndex(objeto => objeto.node.id === idCat)
                      if (indice !== -1) {
                        this.childCategory[indice].node.state = this.statusCat
                      }
                    })
                  }
                } else {
                  this.$refs.searchCat.getCategories()
                }
              }).catch(err => {
                console.log(err)
                showToast(this.$t('code.updateDataError'), 2, this)
              })
          }
        }).catch(() => {

        })
      } else {
        let query = 'mutation{'
        idsCategories.forEach((element, i) => {
          query += `
                  m${i + 1}: updateCategory(id: "${element}", input: {
                    state: ${this.statusCat},
                    isActive:${isActive}   }) {
                    category {
                          id
                          isActive
                        }
                      }
                      `
        })
        query += '}'
        axios
          .post('', {
            query,
          })
          .then(res => {
            messageError(res, this)
            showToast(this.$t('code.updateData'), 1, this)
            if (!this.seeker) {
              if (this.enlace) {
                idsCategories.forEach(idCat => {
                  const indice = this.linkCategory.findIndex(objeto => objeto.node.linkCategory.id === idCat)
                  if (indice !== -1) {
                    this.linkCategory[indice].node.linkCategory.state = this.statusCat
                  }
                })
              } else {
                idsCategories.forEach(idCat => {
                  const indice = this.childCategory.findIndex(objeto => objeto.node.id === idCat)
                  if (indice !== -1) {
                    this.childCategory[indice].node.state = this.statusCat
                  }
                })
              }
            } else {
              this.$refs.searchCat.getCategories()
            }
          }).catch(err => {
            console.log(err)
            showToast(this.$t('code.updateDataError'), 2, this)
          })
      }
    },
    setDeleteCat() {
      this.overlay = true
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          let query = 'mutation{'
          this.selectCategory.forEach((element, i) => {
            query += `
          m${i + 1}: deleteCategory(id: "${element}") {
                found
              }
              `
          })
          query += '}'

          axios
            .post('', {
              query,
            })
            .then(res => {
              messageError(res, this)
              this.overlay = false
              if (this.isSponsor) { checkMultiCategories(this.selectCategory) }

              showToast(this.$t('code.updateData'), 1, this)
              this.updateView()
            }).catch(() => {
              showToast(this.$t('code.updateDataError'), 2, this)
            })
        } else {
          this.overlay = false
        }
      })
    },
    updateParentToInit(id) {
      axios
        .post('', {
          query: `
                query {
                  allCategories(orderBy:"-order",client:"${this.userData.profile.client.id}",
                  ${this.isSponsor ? `sponsor:"${utf8ToB64(`id:${this.userData.id}`)}"` : 'parent_Isnull: true'}                
                  ) {
                      edges {
                      node {
                        id
                        name
                        order
                      }
                    }
                  }
                    }
              `,
        }).then(r => {
          messageError(r, this)
          const catOrder = r.data.data.allCategories.edges[0].node.order + 1
          axios
            .post('', {
              query: `
                  mutation($parent:ID){
                    updateCategory(id: ${id.node.id}, input:{
                      parent: $parent,
                      order: ${catOrder}
                    }) {
                      category{
                        id
                        name
                        client {
                          id
                          name
                        }
                        childCategories {
                          edges {
                            node {
                              id
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                `,
              variables: {
                parent: null,
              },
            }).then(resCat => {
              messageError(resCat, this)
              showToast(this.$t('success'), 1, this)

              this.updateView()
            }).catch(error => {
              showToast(this.$t('error'), 2, this)
              console.log(error)
            })
        }).catch(error => {
          showToast(this.$t('error'), 2, this)
          console.log(error)
        })
    },
    setMoveCat(data) {
      const parentId = data.node.id
      this.overlay = true
      let query = 'mutation{'
      const childCategories = []
      this.selectCategory.forEach((element, n) => {
        childCategories.push(element)
        query += `uc${n + 1}:updateCategory(id:"${element}",input:{
              parent:"${parentId}"
            }){
              category{
                id
                orderType
                name
                client {
                  id
                  name
                }
              }
            }`
      })

      query += '}'

      axios
        .post('', {
          query,
        }).then(res => {
          messageError(res, this)
          this.overlay = false
          showToast(this.$t('code.updateData'), 1, this)
          this.updateView()
        }).catch(() => {
          this.overlay = false
          showToast(this.$t('code.updateDataError'), 2, this)
        })
    },
    removeLink(link) {
      this.overlay = true
      this.$swal({
        title: this.$t('dropLink'),
        text: this.$t('SureRem'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yesRemove'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
                mutation{  
                  deleteLinkCategory(id:"${link.node.id}"){
                    found
                    deletedId
                  }
                }              
              `,
            }).then(res => {
              messageError(res, this)
              this.overlay = false
              this.reloadData(this.code)
              showToast(this.$t('success'), 1, this)
            }).catch(() => {
              showToast(this.$t('error'), 0, this)
            })
        } else {
          this.overlay = false
        }
      }).catch(() => {

      })
    },
    updateOrderLink(event) {
      this.overlay = true
      const arrayNum = []
      const arrayId = []
      const evento = event.target.parentNode.childNodes
      for (let i = 0; i < evento.length; i += 1) {
        if (evento[i]) { // Add null check here
          arrayId.push(evento[i].id.split(' ')[0])
          arrayNum.push(evento[i].id.split(' ')[1])
        }
      }

      arrayNum.sort((a, b) => a - b)

      const dataArr = new Set(arrayNum)

      const result = [...dataArr]
      while (result.length !== arrayNum.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push((n + 1).toString())
      }

      let query = ` mutation
      {`

      for (let i = 0; i < result.length; i += 1) {
        query += `
        m${i + 1}: updateLinkCategory(id:"${arrayId[i]}",input:{ order: ${result[i]
}}){
                    linkCategory{
                    id
                    order
                    }
                  }
                `
      }
      query += `
      }`

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          this.overlay = false
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 0, this)
          this.overlay = false
        })
    },
    contChoice(data) {
      if (data) {
        const [choicesContents, infoContent] = data
        this.choicesContents = choicesContents
        this.infoContent = infoContent
      }
    },
    getLinkCategories(reload = false) {
      this.overlay = true
      this.isLoading = true

      const { scrollPage } = this
      const { scrollY } = window
      let actualPage = scrollPage

      if (reload) {
        this.linkCategory = []
        actualPage = 1
      }
      this.getLinkOffset(reload, scrollPage, actualPage, scrollY)
    },
    getLinkOffset(reload, scrollPage, actualPage = 1, scrollY) {
      const encondedCode = utf8ToB64(`id:${this.code}`)
      const { headers, first } = this

      const query = `{
            linkCategory(parentCategory:"${encondedCode}",
              first:${first},
              offset: ${first * (actualPage - 1)}
              orderBy:"order"){
              pageInfo {
                hasNextPage            
              }
              totalCount
              edges {
                node {
                  id
                  order
                  parentCategory{
                    id
                  }
                  linkCategory {
                    id
                    name
                    order  
                    isFinal
                    isActive
                    state
                    type   
                    image
                    imageUrl
                    mediaLocation    
                    categoryMenu {
                      totalCount                    
                    }  
                    categorySlide {
                        totalCount                                
                    }     
                  }
                }
              }
            }
          }`
      axios
        .post('', {
          query,
        }, { headers }).then(result => {
          messageError(result, this)
          const linkCategories = result.data.data.linkCategory
          this.totalCategories = linkCategories.totalCount

          this.lastOrder = this.totalCategories + 1
          const srch = linkCategories.edges

          this.linkCategory = [...this.linkCategory, ...srch]
          if (!reload) this.scrollPage += 1
          this.hasMore = linkCategories.pageInfo.hasNextPage

          if (actualPage < (scrollPage - 1)) {
            this.getLinkOffset(reload, scrollPage, actualPage + 1, scrollY)
          } else {
            if (scrollY !== null) {
              window.scrollTo(0, scrollY)
            }
            this.overlay = false
            this.isLoading = false
            this.excludeIds = this.linkCategory.map(objeto => objeto.node.linkCategory.id)
          }
        }).catch(err => {
          this.isLoading = false
          this.overlay = false

          console.log(err)
        })
    },
    setStatus() {
      let isActive = false
      let deactivate = false
      let arrayCont = ''
      let estado = ''
      let active = false

      switch (this.state) {
        case 'GRE':
          isActive = true
          active = true
          estado = this.$t('ecommerce.public')

          break
        case 'YEW':
          isActive = true
          active = false
          estado = this.$t('dataGeneric.internal')

          break
        case 'RED':
          estado = this.$t('ecommerce.NoPublic')
          isActive = false
          active = false
          break

        default:
          break
      }
      this.infoContent.forEach(element => {
        if (element.contentSlide.totalCount !== 0 && !active) {
          if (arrayCont) {
            arrayCont += ', '
          }
          arrayCont += element.name
          deactivate = true
        }
      })

      if (deactivate) {
        this.$swal({
          title: this.$t('dataGeneric.atention'),
          text: this.$t('descCont', { arrayCont }),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('next'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            let query = 'mutation{'
            this.choicesContents.forEach((element, i) => {
              query += `
            m${i + 1}:updateContents(id:"${element}",input:{state:${this.state
},isActive:${isActive}}){
              content {
                id
                isActive
              }
            }`
            })
            query += '}'

            axios
              .post('', {
                query,
              })
              .then(res => {
                messageError(res, this)
                deactivateSlider(this.choicesContents, false, 'CONT').then(() => {

                }).catch(err => {
                  console.log(err)
                })
                if (this.choicesContents.length >= 1) {
                  showToast(this.choicesContents.length === 1
                    ? `${this.$t('statusContentCh')} ${estado}` : `${this.$t('statusContentsCh')}  ${estado}`, 1, this)
                }
                this.choicesContents.forEach(idCont => {
                  const indice = this.contentData.findIndex(objeto => objeto.node.id === idCont)
                  if (indice !== -1) {
                    this.contentData[indice].node.state = this.state
                  }
                })
              })
          }
        }).catch(() => {

        })
      } else {
        let query = 'mutation{'
        this.choicesContents.forEach((element, i) => {
          query += `
            m${i + 1}:updateContents(id:"${element}",input:{state:${this.state
},isActive:${isActive}}){
              content {
                id
                isActive
              }
            }`
        })
        query += '}'

        axios
          .post('', {
            query,
          })
          .then(res => {
            messageError(res, this)
            if (this.choicesContents.length >= 1) {
              showToast(this.choicesContents.length === 1
                ? `${this.$t('statusContentCh')} ${estado}` : `${this.$t('statusContentsCh')}  ${estado}`, 1, this)
            }
            this.choicesContents.forEach(idCont => {
              const indice = this.contentData.findIndex(objeto => objeto.node.id === idCont)
              if (indice !== -1) {
                this.contentData[indice].node.state = this.state
              }
            })
          })
      }
    },
    actReturn(data) {
      this.actions = data
    },
    vacio() {
      this.totalContents = 0
      this.seleccionar = false
      this.contentData = []
      this.todos = false
    },

    recursive(id) {
      axios
        .post('', {
          query: `
            query {
              allCategories(id:"${id}",client:"${this.userData.profile.client.id}")  {
              totalCount
                  edges {
                    node {
                      id
                      name                             
                      parent {
                        id
                        name 
                      }         
                      linkCategories {
                      edges {
                        node {
                          id
                          name
                          linkLinkCategory {
                            edges {
                              node {
                                id
                                order
                              }
                            }
                          }
                        }
                      }
                    }        
                  }
                }
            }
        }                        
          `,
        })
        .then(result => {
          messageError(result, this)
          const childCategory = result.data.data.allCategories.edges[0].node
          if (this.enlace) {
            if (childCategory.linkCategories != null) {
              const i = childCategory.parent.id
              const n = childCategory.parent.name

              this.brItems.splice(1, 0, {
                text: n,
                id: i,
              })
              this.recursive(i)
            }
          } else if (childCategory.parent != null) {
            const i = childCategory.parent.id
            const n = childCategory.parent.name

            this.brItems.splice(1, 0, {
              text: n,
              id: i,
            })
            this.recursive(i)
          } else {
            this.navItems = this.brItems.slice()
          }
        })
        .catch(() => { })
    },
    removeSelectLink() {
      this.overlay = true
      this.$swal({
        title: this.$t('dropLink'),
        text: this.$t('SureRem'),
        // text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yesRemove'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          let query = 'mutation{'
          this.infoCategory.forEach((element, i) => {
            query += `
          m${i + 1}: deleteLinkCategory(id: "${element.id}") {
                found
              }
              `
          })
          query += '}'

          axios
            .post('', {
              query,
            })
            .then(res => {
              messageError(res, this)
              this.overlay = false
              showToast(this.$t('code.updateData'), 1, this)
              this.reloadData(this.code)
            }).catch(() => {
              showToast(this.$t('code.updateDataError'), 2, this)
            })
        } else {
          this.overlay = false
        }
      }).catch(err => {
        console.log(err)
        this.overlay = false
      })
    },
    navigationLink(id) {
      axios
        .post('', {
          query: `
            query {
              allCategories(id:"${id}",client:"${this.userData.profile.client.id}")  {
              totalCount
                      edges {
                        node {
                          id
                          name                             
                          parent {
                            id
                            name 
                            isFinal
                          }         
                          linkCategoryCategory(id:"${this.idEnlace}") {
                            totalCount
                            edges {
                              node {
                                id
                                name
                              }
                            }
                          }
                        }        
                      }
                    }   
                  }
                                       
          `,
        })
        .then(result => {
          messageError(result, this)

          const res = result.data.data.allCategories.edges[0].node

          if (res.linkCategoryCategory.totalCount > 0) {
            const i = res.linkCategoryCategory.edges[0].node.id
            const n = res.linkCategoryCategory.edges[0].node.name
            this.brItems.splice(1, 0, {
              text: n,
              id: i,
            })
            this.navItems = this.brItems.slice()
          } else if (res.parent != null) {
            const i = res.parent.id
            const n = res.parent.name
            this.brItems.splice(1, 0, {
              text: n,
              id: i,
            })
            this.navigationLink(i)
          } else {
            this.navItems = this.brItems.slice()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    setIsActive(node, value) {
      const { id, categoryMenu, categorySlide } = node
      let isActive = false
      let active = false
      switch (value) {
        case 'RED':
          isActive = false
          active = false
          break
        case 'YEW':
          isActive = true
          active = false
          break
        default:
          isActive = true
          active = true

          break
      }
      let deactive = false
      if ((categoryMenu.totalCount !== 0 || categorySlide.totalCount !== 0) && !active) {
        deactive = true
        this.$swal({
          title: this.$t('dataGeneric.atention'),
          text: this.$t('descCatAll'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('next'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            const query = `mutation{
                updateCategory(id:"${id}",input:{isActive:${isActive},state:${value}}){
                  category{
                    id
                    name
                    isActive
                  }
                }
              }`
            axios
              .post('', { query }).then(() => {
                showToast(this.$t('success'), 1, this)
                if (categoryMenu.totalCount !== 0) {
                  deactivateMenu([id]).then(() => {
                  }).catch(err => {
                    console.log(err)
                  })
                }
                if (categorySlide.totalCount !== 0) {
                  deactivateSlider([id]).then(() => {
                  }).catch(err => {
                    console.log(err)
                  })
                }
                if (!this.seeker) {
                  if (this.enlace) {
                    const indice = this.linkCategory.findIndex(objeto => objeto.node.linkCategory.id === id)
                    if (indice !== -1) {
                      this.linkCategory[indice].node.linkCategory.state = value
                    }
                  } else {
                    const indice = this.childCategory.findIndex(objeto => objeto.node.id === id)
                    if (indice !== -1) {
                      this.childCategory[indice].node.state = value
                    }
                  }
                } else {
                  this.$refs.searchCat.getCategories()
                }
              }).catch(() => {
                showToast(this.$t('error'), 0, this)
              })
          }
        }).catch(() => {

        })
      }

      if (!deactive) {
        const query = `mutation{
                updateCategory(id:"${id}",input:{isActive:${isActive},state:${value}}){
                  category{
                    id
                    name
                    isActive
                  }
                }
              }`
        axios
          .post('', { query }).then(() => {
            showToast(this.$t('success'), 1, this)
            if (!this.seeker) {
              if (this.enlace) {
                const indice = this.linkCategory.findIndex(objeto => objeto.node.linkCategory.id === id)
                if (indice !== -1) {
                  this.linkCategory[indice].node.linkCategory.state = value
                }
              } else {
                const indice = this.childCategory.findIndex(objeto => objeto.node.id === id)
                if (indice !== -1) {
                  this.childCategory[indice].node.state = value
                }
              }
            } else {
              this.$refs.searchCat.getCategories()
            }
          }).catch(() => {
            showToast(this.$t('error'), 0, this)
          })
      }
    },
    getElement(id = null, link = null, back = null) {
      if (back) {
        window.history.back()
      }
      if (id != null) {
        if (link === 'LIN') {
          if (this.link) {
            this.$router.push({ name: 'link-subcategories', params: { id, parent: this.idEnlace, link: 'LIN' } })
          } else {
            this.$router.push({ name: 'link-categories', params: { id, link: 'LIN' } })
          }
        } else { this.$router.push({ name: 'contents-subcategories', params: { id } }) }
      } else {
        this.$router.push({ name: 'contents-categories' })
      }
    },
    reloadData(id) {
      this.seleccionar = false
      this.todos = false
      this.code = id
      this.overlay = true
      const { scrollPage } = this
      const scroll = window.scrollY
      this.childCategory = []
      this.getCategoryOffset(id, scrollPage, 1, scroll)
    },
    getCategoryOffset(id, scrollPage, actualPage = 1, scrollY) {
      const { headers, first } = this
      axios
        .post('', {
          query: `
          query {
            allCategories(id:"${id}",client:"${this.userData.profile.client.id}"
            )  {
            totalCount
                    edges {
                      node {
                        id
                        isActive
                        state
                        name
                        orderType
                        isFinal
                        type
                        categoryMenu {
                          totalCount                    
                        }
                        categorySlide {
                          totalCount                                
                        }
                         
                        parent {
                          id
                          name                          
                        }     
                         childCategories(${this.isSponsor ? `filter_Sponsor:"${this.userData.id}"` : ''}
                          first:${first},
                          offset:${first * (actualPage - 1)},
                          orderBy:"order"
                         ) {
                          pageInfo {
                            hasNextPage            
                          }
                           totalCount
                            edgeCount                            
                          edges {
                            node {
                            id                              
                            name
                            order    
                            categoryMenu {
                              totalCount                    
                            }      
                            categorySlide {
                              totalCount                                
                            }              
                            image
                            imageUrl
                            mediaLocation
                            isFinal                        
                            isActive
                            state
                            type
                            parent {
                              id
                              name                    
                              isFinal
                            }   
                            }
                          }
                        }  
                        categoryContentOrder {
                          totalCount
                        }
                        categoryContent{
                           totalCount                          
                        }                        
                      }
                  }
                }
              }
          
        `,
        }, { headers }).then(result => {
          messageError(result, this)

          const actualCategory = result.data.data.allCategories.edges[0].node
          this.isContent = actualCategory.isFinal
          this.orderType = actualCategory.orderType
          this.enlace = actualCategory.type === 'LIN'
          this.idParent = actualCategory.parent != null ? actualCategory.parent.id : null
          if (this.isContent) {
            const newOrder = actualCategory.categoryContentOrder.totalCount
            const oldOrder = actualCategory.categoryContent.totalCount
            this.hasNewOrder = newOrder !== 0

            if (this.hasNewOrder && !this.reloadedOrder && (oldOrder !== newOrder)) {
              this.reloadedOrder = true
              checkRelationOrder(id).then(() => {
                this.reloadData(id)
              }).catch(err => {
                console.log(err)
              })
            } else {
              this.getContents(this.hasNewOrder, true)
            }
          } else if (this.enlace) {
            this.getLinkCategories(true)
          } else {
            this.totalCategories = actualCategory.childCategories.totalCount
            this.childCategory = [...this.childCategory, ...actualCategory.childCategories.edges]
            if (actualPage < (scrollPage - 1)) {
              this.getCategoryOffset(id, scrollPage, actualPage + 1, scrollY)
            } else {
              if (scrollY !== null) {
                window.scrollTo(0, scrollY)
              }
              this.overlay = false
            }
            this.contentData = []
          }
        }).catch(err => {
          console.log(err)
          this.overlay = false
        })
    },
    clickCheck(node) {
      const i = this.selectCategory.indexOf(node.id)
      const a = this.infoCategory.map(e => e.id).indexOf(node.id)

      if (i !== -1) {
        this.selectCategory.splice(i, 1)
        this.infoCategory.splice(a, 1)
      } else {
        this.selectCategory.push(node.id)
        this.infoCategory.push(node)
      }
    },
    pagCount(data) {
      const [totalCategories, rows] = data
      this.totalCategories = totalCategories
      this.rows = totalCategories
      this.rows = rows
      this.hiddeSearchInput = false
      setTimeout(document.getElementById('search').focus(), 3000)
    },
    goToInit(item) {
      let go = false
      if (!this.isSponsor) {
        go = true
      } else {
        return false
      }
      if (item.node.parent) {
        go = true
      } else {
        return false
      }
      /*  if (item.node.type !== 'SER' && item.node.type !== 'PRO') {
        go = true
      } else {
        return false
      } */
      return go
    },
    checkSponsorIdOnParent(parentID) {
      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
          query {
            allCategories(id:"${parentID}",client:"${this.userData.profile.client.id}")  {
            totalCount
                    edges {
                      node { 
                        id
                        name
                        sponsor {
                          edges {
                            node {
                              id
                            }
                          }
                        }       
                        parent {
                          id
                        }   
                      }
                  }
              }
          }          
        `,
          }).then(result => {
            const actualCategory = result.data.data.allCategories.edges[0].node
            const sponsorEdges = actualCategory.sponsor.edges
            if (sponsorEdges.some(edge => edge.node.id === this.userData.id)) {
              return resolve(true)
            }
            if (!actualCategory.parent) {
              return resolve(false)
            }
            return this.checkSponsorIdOnParent(actualCategory.parent.id)
              .then(resolve)
              .catch(reject)
          })
      })
    },
  },

}
</script>

<style lang="scss" scoped>
#categorias .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#categorias .card-body {
  padding: 0rem;
}

#categorias .card-body h4 {
  font-size: 1rem !important;
}

#categorias .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  //z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  /*  -webkit-justify-content: flex-end;
  justify-content: flex-end; */
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

#categorias .dropContainer {
  border: 3px dashed;
}

#categorias .editIcon {
  color: darkcyan;
  margin-right: 10px;
}

#categorias .deleteIcon {
  color: red;
}

#categorias .breadcrumb {
  justify-content: flex-start;
  font-weight: bold;
}

#categorias #button-content {
  padding: 0px;
}

#categorias #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#categorias #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

#categorias .carCat img {
  height: 11vw;
  object-fit: cover;
}

@media screen and (max-width: 991px) {
  #categorias .carCat img {
    height: 35vw;
    object-fit: cover;
  }
}

@media screen and (min-width: 992px) {

  //  Estilos para pantallas grandes (más de 992px de ancho)
  #categorias .carCat img {
    height: 20vw;
    object-fit: cover;

  }
}

@media screen and (min-width: 1250px) {

  //  Estilos para pantallas grandes (más de 992px de ancho)
  #categorias .carCat img {
    height: 12vw;
    object-fit: cover;

  }
}

#categorias .searchCategories {
  width: 140px;
  box-sizing: border-box;
  font-size: 16px;
  background-image: url('~@/assets/images/icons/searchIcon.png');
  background-position: 10px 8px;
  background-repeat: no-repeat;
  padding: 10px 10px 10px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}

#categorias .searchCategories:active {
  cursor: pointer;
}

#categorias .searching {
  width: 100%;

}

.manualOrder {
  border: 5px dashed #e69d5d;
  background: repeating-linear-gradient(-55deg,
      #283046,
      #283046 10px,
      #161d31 10px,
      #161d31 20px);
}

@-webkit-keyframes hang-on-hover {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hang-on-hover {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@-webkit-keyframes hang-on-hover-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hang-on-hover-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

.hang-on-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hang-on-hover:hover,
.hang-on-hover:focus,
.hang-on-hover:active {
  -webkit-animation-name: hang-on-hover-sink, hang-on-hover;
  animation-name: hang-on-hover-sink, hang-on-hover;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

.overlay-container {
  position: fixed;
  /* Posición fija en la ventana del navegador */
  top: 0;
  /* Coloca el contenedor en la parte superior */
  left: 130px;
  /* Coloca el contenedor en el lado izquierdo */
  width: 100%;
  /* Ancho completo */
  height: 100%;
  /* Altura completa */
  display: flex;
  /* Activa el modelo de caja flexible */
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
  z-index: 9999;
  /* Z-index alto para asegurar que esté por encima de otros elementos */
}
</style>
